<template>
    <section class="login section section-shaped section-lg my-0">
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h5 class="color-black text-center text-muted mb-4">
                        Спасибо, что пользуетесь нашей системой
                    </h5>
                    

                    <div class="text-center">
                        <!--<a href="/">Вернуться на главную</a>-->
                        <base-button @click="gohome" type="danger" class="mb-4">Вернуться на главную</base-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        components: {

        },
        data: () => ({
            redirecUrl: 'login'
        }),
        watch: {

        },
        mounted() {

            if (localStorage.getItem("isLamoda")) {
                localStorage.removeItem("isLamoda");
                this.redirecUrl = 'login/lamoda';
            }
        },
        methods: {
            gohome: function () {
                    window.location = this.redirecUrl;
            }

        },
        //created: {

        //},

        // Модель для валидации, которую Vuelidate превратит в computed-поле $v


    };
</script>

