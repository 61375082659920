import ticketService from '@/services/ticket.service';
//import axios from 'axios';
export default {
    async GET_TICKETS({ commit, getters }, { page, withCleaning, filter }) {
        const take = getters.take;
        const skip = page ? (take * (page - 1)) : 0;
        
        const { items, totalCount } = await ticketService.getTickets(skip, take, filter);//ticketsTotalCount
        commit('UpdateTickets', { tickets: items, withCleaning });
        commit('UpdateTicketsTotalCount', totalCount);
        if (!withCleaning)
            commit('IncrementPage');
        else
            commit('ResetPageTo', 1);
    },
    async GET_TICKET({ commit }, id) {
        const ticket = await ticketService.getTicket(id);
        commit('UpdateTicket', ticket);
    },
    async SAVE_TICKET({ commit }, ticket) {
        await ticketService.saveTicket(ticket);
        const savedTicket = await ticketService.getTicket(ticket.id);
        commit('UpdateTicket', savedTicket);
    },
    async ADD_TICKET({ commit }, ticket) {
        const savedTicketId = await ticketService.addTicket(ticket);
        const savedTicket = await ticketService.getTicket(savedTicketId);
        commit('AddTicket', savedTicket);
        commit('UpdateNewTicketId', savedTicket.id);
        //return savedTicket.id;
    },
    async DELETE_TICKET({ commit }, id) {
        await ticketService.deleteTicket(id);
        commit('DeleteTicket', id);
    },
    //async CLOSED_TICKET({ commit }, ticket) {
    //        await ticketService.saveTicket(ticket);
    //        commit('SaveTicket', id);
        
    //},
    async FETCH_TICKET_TYPES({ commit }) {
        const types = await ticketService.getTicketTypes();
        commit('UpdateTicketTypes', types);
    }
};
