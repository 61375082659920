<template>
    <div class="input-group daterange jquery-ignore">
        <input type="text"
               class="form-control"
               :placeholder="placeholder"
               @input="onInput($event.target.value)">
        <span class="datetimepicker-addon input-group-addon">
            <!--<img :src="icon" />-->
            <!--:data-dateformat="dateformat"
            :data-defaultdate="defaultdate"-->
        </span>
    </div>
</template>
<script>
    import $ from 'jquery';
    import moment from 'moment';
    
    export default {
        //name:'datetimepickerRange',
        props: {
            //'defaultdate': {
            //    type: String,
            //},
            //'dateformat': {
            //    type: String,
            //    default:'DD.MM.YY'
            //},
            /**
             * Поддерживаемый формат входной и выходной даты (При update:***-value).
             * Переданная дата должна в точности соответствовать данному формату.
             * */
            'supportedFormat': {
                type: String,
                default: 'DD.MM.YYYY HH:mm:ss Z'
            },
            /**
             * Начальная дата интервала
             * */
            'startvalue': {
                type: String,
                default: null
            },
            /**
             * Конечная дата интервала
             * */
            'endvalue': {
                type: String,
                default: null
            },
            /**
             * placeholder, который будет отображаться в input
             * */
            'placeholder': {
                type: String,
                default: 'Выберите дату'
            },
            /**
             * Иконка, которая будет отображаться рядом с input
             * */
            'icon': {
                type: String,
                default: 'img/svg/datetimepicker.svg'
            },
            /**
             * смещение времени, timezone offset пользователя. Переданная дата будет отображена с учётом данной опции.
             * Можно не указывать, тогда значение времени будет соответствовать времени события.
             * Возможные варианты: -10:30:00, -10:30, +10:30:00, +10:30, 10:30:00, 10:30, null.
             * */
            'timezoneOffset': {
                type: String,
                default: null,
                validator(value) {
                    let result = value.search(/^((\+|-|)\d{2}:\d{2}(:\d{2}|))$/);
                    return result !== -1 ? true : false;
                }
            }
        },
        created() {

        },
        mounted() {
            this.init();
        },
        data: function () {
            return {

            }
        },
        computed: {
            /**
             * Начальная дата, вычисленная из входящего параметра
             * */
            startDate() {
                let momentDate = null;
                if (this.startvalue !== null) {
                    momentDate = moment(this.startvalue, this.supportedFormat, true);
                    if (!momentDate.isValid()) {
                        throw "Неверный формат даты start, в качестве входного параметра. Разрешённые форматы: " + this.supportedFormat;
                    }
                    if (this.userTimezoneOffsetNormalized !== null) {
                        momentDate = momentDate.utcOffset(this.userTimezoneOffsetNormalized);
                    }
                }
                return momentDate;
            },
            /**
             * Конечная дата, вычисленная из входящего параметра
             * */
            endDate() {
                let momentDate = null;
                if (this.endvalue !== null) {
                    momentDate = moment(this.endvalue, this.supportedFormat, true);
                    if (!momentDate.isValid()) {
                        throw "Неверный формат даты end, в качестве входного параметра. Разрешённые форматы: " + this.supportedFormat;
                    }
                    if (this.userTimezoneOffsetNormalized !== null) {
                        momentDate = momentDate.utcOffset(this.userTimezoneOffsetNormalized);
                    }
                }
                return momentDate;
            },
            /**
             * Нормализованное смещение времени до формата +00:00
             * */
            userTimezoneOffsetNormalized() {
                let result = null;
                if (this.timezoneOffset !== null) {
                    let tzOffset = this.timezoneOffset;
                    let direction = '+';
                    if (tzOffset.charAt(0) === '-') {
                        direction = '-';
                    }
                    tzOffset = tzOffset.replace('-', '').replace('+', '');
                    let array = tzOffset.split(':');

                    result = `${direction}${array[0]}:${array[1]}`;
                }
                return result;
            }
        },
        methods: {
            /**
             * Инициализация daterangepicker
             * */
            init() {

                //http://www.daterangepicker.com/#config
                const $this = $(this.$el);
                $this.daterangepicker({
                    "showDropdowns": true,
                    "timePicker": true,
                    "linkedCalendars": false,
                    "timePicker24Hour": true,
                    "timePickerSeconds": false,
                    "ranges": {
                        'Сегодня': [moment().startOf('day'), moment().endOf('day')],
                        'Вчера': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'За последние 7 дней': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                        'За последние 30 дней': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                        'За текущий месяц': [moment().startOf('month'), moment().endOf('month')],
                        'За предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    },
                    "locale": {
                        "format": "DD.MM.YYYY, HH:mm",
                        //"separator": " - ",
                        "applyLabel": "Принять",
                        "cancelLabel": "Отменить",
                        "fromLabel": "От",
                        "toLabel": "До",
                        "customRangeLabel": "Свой интервал",
                        "firstDay": 1,
                        "daysOfWeek": [
                            "Вс",
                            "Пн",
                            "Вт",
                            "Ср",
                            "Чт",
                            "Пт",
                            "Сб"
                        ],
                        "monthNames": [
                            "Январь",
                            "Февраль",
                            "Март",
                            "Апрель",
                            "Май",
                            "Июнь",
                            "Июль",
                            "Август",
                            "Сентябрь",
                            "Октябрь",
                            "Ноябрь",
                            "Декабрь"
                        ],
                    },
                    "alwaysShowCalendars": true,
                    
                    //"autoUpdateInput" : false,
                    //"startDate": , //"12/13/2017"
                    //"endDate": this.endDate !== null ? this.endDate : ''//"12/19/2017"
                }, function (start, end, label) {
                });

                //Инициализация и установка значений
                if (this.startDate !== null)
                    $this.data('daterangepicker').setStartDate(this.startDate);
                if (this.endDate !== null)
                    $this.data('daterangepicker').setEndDate(this.endDate);
                this.apply($this, this.startDate, this.endDate);
                ////////////////////////////////////////////////////////////////////////

                $(this.$el).on('apply.daterangepicker', (ev, picker) => {
                    let _this = ev.currentTarget;
                    this.apply(_this, picker.startDate, picker.endDate);
                });

                $(this.$el).on('cancel.daterangepicker', (ev, picker) => {
                    let _this = ev.currentTarget;
                    this.cancel(_this);
                });
            },
            /**
             * Примененяет выбранный интервал дат
             * @param _this Объект Jquery (инициализированный daterangepicker )
             * @param startDate Начальная дата
             * @param endDate Конечная дата
             */
            apply(_this, startDate, endDate) {
                this.changeValues(startDate, endDate);
                $(_this).find('input').val((startDate !== null ? startDate.format('DD.MM.YYYY, HH:mm') : '')
                    +
                    (endDate !== null ? (' - ' + endDate.format('DD.MM.YYYY, HH:mm')) : ''));
            },
            /**
             * отменяет установку выбранного интервала дат
             * @param _this
             */
            cancel(_this) {
                $(_this).find('input').val('');
                this.changeValues(null, null);
            },
            //При редактировании вручную (прописывание в input)
            /**
             * Срабатывает при ручном редактировании даты
             * @param value
             */
            onInput(value) {
                let array = value.split('-');
                let count = array.length;
                let datetimeStart = null;
                let datetimeEnd = null;
                if (count > 0)
                    datetimeStart = moment(array[0].trim(), 'DD.MM.YYYY, HH:mm', true);
                if (count > 1)
                    datetimeEnd = moment(array[1].trim(), 'DD.MM.YYYY, HH:mm', true);

                this.changeValues(
                    datetimeStart !== null && datetimeStart.isValid() ? datetimeStart : null,
                    datetimeEnd !== null && datetimeEnd.isValid() ? datetimeEnd : null
                );

            },
            //методы изменения значений
            /**
             * Измененяет начальную и конечную даты интервала
             * @param startDate
             * @param endDate
             */
            changeValues(startDate, endDate) {
                this.changeStartDate(startDate);
                this.changeEndDate(endDate);
            },
            /**
             * Измененяет начальную дату
             * @param startDate дата, на которую будет изменена начальная дата
             */
            changeStartDate(startDate) {
                let _d = startDate;
                if (this.userTimezoneOffsetNormalized !== null) {
                    _d = moment(_d).format(`YYYY-MM-DDTHH:mm:ss${this.userTimezoneOffsetNormalized}`);
                }
                this.$emit('update:startvalue', startDate !== null ? moment.parseZone(_d).format(this.supportedFormat) : null);
            },
            /**
             * Измененяет конечную дату
             * @param endDate дата, на которую будет изменена конечная дата
             */
            changeEndDate(endDate) {
                let _d = endDate;
                if (this.userTimezoneOffsetNormalized !== null) {
                    _d = moment(_d).format(`YYYY-MM-DDTHH:mm:ss${this.userTimezoneOffsetNormalized}`);
                }
                this.$emit('update:endvalue', endDate !== null ? moment.parseZone(_d).format(this.supportedFormat) : null);
            },
        },
        //components: {
        //
        //}
    }
</script>
<style scoped>
    input{
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: #999999 1px solid;
        border-radius: 0;
        padding: 0;
        height: auto;
        padding-top: 0px;
        padding-bottom: 5px;
            box-shadow: none;
    }
</style>
