<template>
    <div class="row plate" :data-info-ticket-id="ticket.id">
        <div class="col-lg-8 ticket-content">
            <div>
                <router-link :to="'/tickets/' + ticket.id">
                    <span class="ticket-title">{{ticket.title}}</span>
                </router-link>
                <span v-if="ticket.number" class="number color-gray pl-1 pr-2">
                    #{{ticket.number}}
                </span>
                <span class="state " :class="statusClass">{{statusText}}</span>
            </div>
            <div class="ticket-description">
                <template v-if="ticket.type">
                    <div class="color-gray">{{ticket.type}}</div>
                </template>
                <template v-if="ticket.text">
                    <span class="ticket-text" :class="{'show-all': showAll}" ref="infoBox">
                        {{ticket.text}}
                    </span>
                    <div class="btn-text fs-13 show-text">
                        <span v-show="!showAll && show"
                              class="color-gray"
                              @click="showText()">
                            Показать весь текст
                            <i class="fa fa-caret-down pl-2" aria-hidden="true"></i>
                        </span>
                        <span v-show="showAll && show"
                              @click="showText()">
                            Скрыть текст
                            <i class="fa fa-caret-up pl-2" aria-hidden="true"></i>
                        </span>
                    </div>
                </template>
            </div>
        </div>
        <div class="col-lg-4 ticket-change">
            <div><span class="color-gray">Создан: </span>{{ticket.createdDate | elapsedTimeString(60)}}</div>
            <div v-if="ticket.closedDate && ticket.status == 2"><span class="color-gray">Закрыт: </span>{{ticket.closedDate | elapsedTimeString(60)}}</div>
            <div><span class="color-gray">Ответственный: </span>{{ticket.performer?ticket.performer.caption:'Н/Д'}}</div>
            <div class="icon-container">
                <router-link :to="'/tickets/' + ticket.id"  tag="button" :disabled="ticket.countFiles === 0">
                    <span class="btn-text" :class="{'empty': ticket.countFiles === 0 }">{{ticket.countFiles || 0}}<i class="fa fa-file-o pl-2" aria-hidden="true"></i></span>
                </router-link>
                <router-link :to="'/tickets/' + ticket.id" tag="button" :disabled="ticket.countComments === 0">
                    <span class="pl-4 btn-text" :class="{'empty': ticket.countComments === 0 }">{{ticket.countComments || 0}}<i class="fa fa-comment-o pl-2" aria-hidden="true"></i></span>
                </router-link>
               
            </div>
        </div>
    </div>
</template>
<script>
    import { TicketStatus } from '../../models/ticket.js';
    export default {
        props: {
            'ticket': {
                type: Object,
                required:true
            }
        },
        data: function () {
            return {
                show:false,
                showAll: false,
                maxHeight: 63
            }
        },
         mounted () {
           this.matchHeight()
         },
        computed: {
            ticketStatus() {
                return TicketStatus;
            },
            statusClass() {
                let styleClass = '';
                switch (this.ticket.status) {
                    case TicketStatus.New:
                        styleClass = 'new-state';
                        break;
                    case TicketStatus.InWorking:
                        styleClass = 'active-state';
                        break;
                    case TicketStatus.Closed:
                        styleClass = 'close-state';
                        break;
                    default:
                }
                return styleClass;
            },
            statusText() {
                let value = this.ticket.status;
                let statusText = '';
                switch (value) {
                    case TicketStatus.New:
                        statusText = 'Новый';
                        break;
                    case TicketStatus.InWorking:
                        statusText = 'В работе';
                        break;
                    case TicketStatus.Closed:
                        statusText = 'Закрыт';
                        break;
                    default:
                }
                return statusText;
            }
        },
        methods: {
            showText() {
                this.showAll = !this.showAll;
            },
            matchHeight() {
                if (this.$refs.infoBox) {
                    var heightString = this.$refs.infoBox.clientHeight;
                    if (heightString >= this.maxHeight) {
                        this.show = true;
                    }
                }
            }
        }
    };
</script>
<style>
    .ticket-title {
        font-size:18px;
    }
    .number {
        font-size: 16px;
    }
</style>
