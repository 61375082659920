<template>
    <header class="header-global">
        <base-nav class="bg-white navbar-main" type="" effect="light" expand>
            <router-link slot="brand" to="/"
                         class="navbar-brand mr-lg-5">
                <!--<img src="../assets/img/logo.svg" alt="logo">-->
                <img src="../assets/img/logo_in_login.svg" alt="logo" class="" />
                <!--<span class="ml-2 mb--2 text-bottom color-black">Портал</span>-->
            </router-link>
            <router-link to="/tickets" class="color-black p-3 nav">
                Обращения
            </router-link>
            <!--<router-link to="/tickets/12" class="btn   color-black">
                База знаний
            </router-link>-->

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                <base-dropdown tag="li" class="nav-item text-right">
                    <a slot="title"
                       href="#"
                       class="nav-link"
                       data-toggle="dropdown"
                       role="button">
                        <span class="nav-link-inner--text color-black">
                            {{organization.caption}}
                        </span>
                        <i class="fa fa-caret-down pl-2" aria-hidden="true"></i>
                    </a>
                    <a v-for="organization in organizations"
                       :key="organization.id"
                       class="dropdown-item"
                       @click="changeOrganization(organization)">
                        <span>{{organization.caption}}</span>
                    </a>

                </base-dropdown>
            </ul>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center account">
                <base-dropdown tag="li" class="nav-item text-right">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <span class="user-avatar"><img src="../assets/img/male.png" /></span>
                        <span class=" ml-2 nav-link-inner--text color-black">{{profile.firstName}} {{profile.lastName}}</span>
                        <i class="fa fa-caret-down pl-2" aria-hidden="true"></i>
                    </a>
                    <!--<router-link to="/profile" class="dropdown-item">Профиль</router-link>-->
                    <router-link to="/logout" class="dropdown-item"><span @click="logout">Выйти</span></router-link>
                </base-dropdown>
            </ul>
        </base-nav>

    </header>
</template>

<style>
    .navbar-collapse {
        position: relative;
    }

        .navbar-collapse .account {
            position: absolute;
            right: 0;
        }

    .user-avatar img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
    }

    .navbar-main .nav {
        background: rgba(34, 34, 34, 0.05);
        border-radius: 3px;
    }

    .dropdown-menu .dropdown-item {
        padding: 0px;
    }

     .dropdown-menu .dropdown-item span {
        padding: 8px 16px;
        width: 100%;
        display:block;
    }
    /*.navbar-main .btn{
            background-color: #fff;
        border-color: #fff;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    .navbar-main .btn.active{
            background-color: #fff;
        border-color: #fff;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }*/
</style>

<script>
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
    import BaseNav from "@/components/BaseNav";
    import BaseDropdown from "@/components/BaseDropdown";
    import CloseButton from "@/components/CloseButton";
    import UserService from '../services/user.service';
    import TicketService from '../services/ticket.service';

    export default {
        data: function () {
            return {
                profile: {},
                organization: {
                    caption: 'orgname',
                    id: ''
                },
                organizations: []
            }
        },
        computed: {
        },


        methods: {
            ...mapActions('ticketModule', [
                'GET_TICKETS',
                'SAVE_TICKET',
                'ADD_TICKET',
                'DELETE_TICKET',
                'FETCH_TICKET_TYPES'
            ]),
            ...mapActions('organizationModule', [
                'SET_ORGANIZATION'
            ]),
            async search(isStart = true) {
                if (!this.loading) {
                    this.loading = true;
                    let requestedPage = isStart ? 1 : this.page + 1;
                    await this.GET_TICKETS({ page: requestedPage, withCleaning: isStart });
                    this.loading = false;
                }
            },
            logout: async function (e) {
                //e.preventDefault();
                await UserService.logout();
                //this.$router.push('login');
            },
            changeOrganization: async function (org) {
                await TicketService.changeOrganization(org.id);
                this.organization = org;
                await this.SET_ORGANIZATION(org);
                await this.FETCH_TICKET_TYPES();
                this.search(true);
            },
        },
        async mounted() {
            this.profile = await UserService.getProfile();
            this.organizations = await UserService.getOrganizations();
            this.organization = this.organizations ? this.organizations[0] : null;
            await this.changeOrganization(this.organization);

        },
        components: {
            BaseNav,
            CloseButton,
            BaseDropdown
        },
    };
</script>

