export default function (num, One, TwoToFour, Many) {
    let str = "";
    if (!(num > 10 & num < 21)) {
        switch (num % 10) {
            case 1:
                str = One;//" секунду"
                break;
            case 2:
            case 3:
            case 4:
                str = TwoToFour;
                break;
            default:
                str = Many;
                break;
        }
    }
    else {
        str = Many;
    }

    return `${num} ${str}`;
}