<template>
    <div class="">
        <div>
            <span class="mr-2">
                {{commentsTotalCount  | getNumPluralizedString('комментарий', 'комментария', 'комментариев')}}
            </span>
            <!--<span class="color-gray">История рассмотрения</span>-->
        </div>
        <!--<div v-show="loadingComments">
             <span>Загрузка...</span>
     </div>-->
        <div class="comment-list">
            <transition-group name="slider">
                <div v-for="comment in comments" class="mt-3 comment" :key="comment.id">
                    <div>
                        <img src="http://img.animal-photos.ru/foxes/fox2.jpg" class="mr-2" />
                    </div>
                    <div class="comment-content">
                        <div>
                            <span class="mr-2">{{comment.author}}</span>
                            <span class="mb-2 color-gray">{{comment.createdDate | elapsedTimeString}}</span>
                        </div>
                        <span class="comment-text">{{comment.text}}</span>
                    </div>
                </div>
            </transition-group>
        </div>
        <base-pagination v-if="commentsTotalCount > commentsTake"
                         align="center"
                         :per-page="commentsTake"
                         :total="commentsTotalCount"
                         :value="commentsPage"
                         @input="changeCommentPage">

        </base-pagination>
    </div>
</template>
<script>
    import { TicketStatus } from '@/models/ticket.js';
    import ticketService from '@/services/ticket.service';
    export default {
        props: {
            'ticket': {
                type: Object,
            }
        },
        data: function () {
            return {
                comments: [],
                commentsTake: 10,
                commentsPage: 0,
                commentsTotalCount: 0,
                show: false,
            }
        },
        computed: {
            statusClass() {
                let styleClass = '';
                switch (this.ticket.status) {
                    case TicketStatus.New:
                        styleClass = 'new-state';
                        break;
                    case TicketStatus.InWorking:
                        styleClass = 'active-state';
                        break;
                    case TicketStatus.Closed:
                        styleClass = 'close-state';
                        break;
                    default:
                }
                return styleClass;
            }
        },
        filters: {
            statusText(value) {
                let statusText = '';
                switch (value) {
                    case TicketStatus.New:
                        statusText = 'Новый';
                        break;
                    case TicketStatus.InWorking:
                        statusText = 'В работе';
                        break;
                    case TicketStatus.Closed:
                        statusText = 'Закрыт';
                        break;
                    default:
                }
                return statusText;
            }
        },
        methods: {
            showText() {
                this.show = !this.show;
            },
            async getComments(ticketId, page) {
                this.loadingComments = true;
                const take = 10;
                const skip = page ? take * (page - 1) : 0;
                let { items, totalCount } = await ticketService.getComments(ticketId, skip, take);
                this.comments = items;
                this.commentsTotalCount = totalCount;

                this.commentsPage = page;
                this.loadingComments = false;
            },
        }
    };
</script>
<style>
</style>
