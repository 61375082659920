<template>
    <select class="vue-select  search select2 select2-ignore jquery-ignore"
            :multiple="multiple">
        <slot></slot><!--:data-dictionaryid="dictionaryid"-->
    </select>
</template>
<script>
    import $ from 'jquery';
    //import vSelect from 'vue-select';v-on:change="$emit('change', $event.target.value)"
    import isEqual from 'lodash/isEqual';
    export default {
        //name: 'select',
        props: {
            /**
             * https://select2.org/placeholders
             * */
            'placeholder': {
                type: String,
                default: 'Выберите значение'
            },
            /**
             * https://select2.org/selections#clearable-selections
             * */
            'allowClear': {
                type: Boolean,
                default: false
            },
            /**
             *
             * */
            'allowTransformData': {
                type: Boolean,
                default: false
            },
            /**
             *
             * */
            'multiple': {
                type: Boolean,
                default: false
            },
            /**
             * https://select2.org/appearance#container-width
             * */
            'width': {
                type: String,
                default: '100%'//'resolve'
            },
            /**
             *
             * */
            'url': {
                type: String,
                default: null
            },
            'autoRequestSelectedElement': {
                type: Boolean,
                default: false
            },
            'autoRequestSelectedElementUrl': {
                type: String,
                default: null
            },
            /**
             *
             * */
            'dropdownAutoWidth': {
                type: Boolean,
                default: false
            },
            /**
             * @deprecated
             * */
            //'fieldid': {
            //    type: String,
            //    default: null
            //},
            /**
             * The maximum number of items that may be selected in a multi-select control. If the value of this option is less than 1,
             * the number of selected items will not be limited.
             * */
            'maximumSelectionLength': {
                type: Number,
                default: 0
            },
            /**
             * Maximum number of characters that may be provided for a search term.
             * */
            'maximumInputLength': {
                type: Number,
                default: 0
            },
            /**
             * https://select2.org/searching#minimum-search-term-length
             * */
            'minimumInputLength': {
                type: Number,
                default: 0
            },
            /**
             * https://select2.org/searching#limiting-display-of-the-search-box-to-large-result-sets
             * */
            'minimumResultsForSearch': {
                type: Number,
                default: 20//0
            },
            /**
             * https://select2.org/tagging
             * */
            'tags': {
                type: Boolean,
                default: false
            },
            'emailTags': {
                type: Boolean,
                default: false
            },
            /**
             * https://select2.org/tagging#customizing-tag-placement-in-the-dropdown
             * */
            'insertTag': {
                type: Function,
                default: null
            },
            /**
             * https://select2.org/tagging#constraining-tag-creation
             * */
            'createTag': {
                type: Function,
                default: null
            },
            /**
             *
             * */
            'colorText': {
                type: String,
                default: null
            },
            /**
             *
             * */
            'colorIcon': {
                type: String,
                default: null
            },
            /**
             *
             * */
            'dropdownCssClass': {
                type: String,
                default: ''
            },
            /**
             * https://select2.org/i18n#message-translations
             * */
            // 'language': {
            //     type: String,
            //     default: "ru"//"ru"
            // },
            /**
             *
             * */
            'templateResult': {
                type: Function,
                default: null
            },
            'templateSelection': {
                type: Function,
                default: null
            },
            'ajaxData': {
                type: Function,
                default: null
            },
            'ajaxProcessResults': {
                type: Function,
                default: null
            },
            'ajaxHeaders': {
                type: Object,
                default() {
                    return {};
                }
            },
            'transport': {
                type: Function
            },
            /**
             * Кол-во выбранных элементов, которые будут отображаться в select.
             * Если их больше этого кол-ва, то будет отображаться надпись "Ещё n", где n
             * кол-во неотображаемых элементов
             * */
            'quantitySelectedToDisplay': {
                type: Number,
                default: 1
            },
            'showAll': {
                type: Boolean,
                default: false
            },
            ///**
            // * @deprecated
            // * TODO:Выпилить
            // * */
            //'dictionaryid': {
            //    type: String,
            //    default: null
            //},
            /**
             * Объект, идентификатор, массив объектов (в случае multiple)
             * или массив идентификаторов (в случае multiple), указывающие на выбраные элементы
             * */
            'value': {
                type: [Array,Object,String,Number],
                //default() {
                //    return []
                //}
            },
            /**
             * Набор элементов селекта
             * */
            'options': {
                type: Array,
                default() {
                    return null
                }
            },
            /**
             * Атрибуты, которые позволяют
             * */
            'allowedAttrs': {
                type: Array,
                default() {
                    return null
                }
            },
            /**
             * Отключить селект
             * */
            'disabled': {
                type: Boolean,
                default() {
                    return false
                }
            },
            /**
             * Указывает на свойство в исходном объекте, которое
             * будет спроецировано на значение атрибута [value] элемента в селекте
             * */
            'propertiesMapping': {
                type: Object,
                default: () => {
                    return {};
                }
            },
            /**
             * Enable debugging messages in the browser console.
             * */
            'debug': {
                type: Boolean,
                default: false
            },
            /*
             * Handles custom search matching.
             * https://select2.org/searching#customizing-how-results-are-matched
             * */
            'matcher': {
                type: Function
            }
        },
        mounted() {
                //Поддержка слотов (вложенные элементы option), если prop options не указан
                let slots = this.$slots;
                let defaultSlot = slots.default;
                if (!this.options) {
                    if (defaultSlot && defaultSlot.length > 0) {
                        this.optionsArray = this.getOptionsFromSlot(defaultSlot);
                        slots.default = null;//???
                    }
                }
                else {
                    this.optionsArray = this.setOptionsFromProp(this.options);
                }
            //////////////////////////////////////////////////

            //Инициализация
            this.onBeforeInit();
            let $select = this.init();
            this.onInit($select);
            this.$once('hook:destroyed', () => {
                //$select.css('opacity', 0);//TODO:При дестрое select2 становиться обычным селектом. Выглядит не очень

                if (this.debug)
                    console.log('vue-select:destroy');

                $select.css('opacity', 0);
                $select.off().select2('destroy');
            });
            //////////////////////////////////////////////////
            if (this.autoRequestSelectedElement) {
                $.ajax({
                    url: this.autoRequestSelectedElementUrl || this.url,
                    success: (result) => {
                        //const selectedValues = this.getSelectedValues(this.value);
                        let ops = [];
                        if (Array.isArray(result))
                            ops = this.getFilterAttrs(result);
                        else if(typeof result === 'object' || result !== null)
                            ops = this.getFilterAttrs([ result ]);
                        this.setOptionsFromProp(ops);
	                },
                });
            }
        },
        //beforeDestroy() {
        //
        //    $(this.$el).off().select2('destroy');
        //},
        data: function () {
            return {
                //$select: null,
                //optionsByAjax: null
                loadedOptionsByAjaxCount:0,
                optionsArray: [],
                /**
                 * Флаг того что происходит принудительное открытие
                 * */
                forcedOpening: false,
                /**
                 * Флаг того что происходит принудительное закрытие
                 * */
                forcedClosing: false
            }
        },
        computed: {
            /**
             *  Массив выбранных значений. В случае если multiple не указан, берётся первый элемент из массива,
             *  либо в массиве всего один элемент (если в качестве value передан не массив)
             *  Т.е. вне зависимости от того single или multiple, результатом всегда будет массив
             * */
            selectedValues() {
                const array = this.getSelectedValues(this.value);
                return array;
            },
            mapPropToId() {
                return this.propertiesMapping['id'] || 'id';
            },
            mapPropToText() {
                return this.propertiesMapping['text'] || 'text';
            },
            mapPropToSubtext() {
                return this.propertiesMapping['subtext'] || 'subtext';
            },
            mapPropToSelected() {
                return this.propertiesMapping['selected'] || 'selected';
            },
            mapPropToDisabled() {
                return this.propertiesMapping['disabled'] || 'disabled';
            },
            mapPropToLevel() {
                return this.propertiesMapping['level'] || 'level';
            },
            mapPropToChildren() {
                return this.propertiesMapping['children'] || 'children';
            }
        },
        watch: {
            selectedValues(newValue, oldValue) {
                //Если програмно обнуляется выбранное значение
                if (!isEqual(newValue, oldValue)) {
                    if (newValue === null && $(this.$el).val()) {
                        //console.log(1);
                        $(this.$el).val(null).trigger('change');
                    }
                    else {
                        $(this.$el).val(newValue).trigger('change');
                    }
                    if (this.debug)
                        console.log('Новое значение', newValue);
                    if (this.debug)
                        console.log('Старое значение', oldValue);
                }
            },
            options(options) {
                ////////////////////////////////////////////////////////////////
                if (!this.url) {
                    const ops = this.getFilterAttrs(options);
                    //////////////////////////////////////////////////////////////////////
                    this.setOptionsFromProp(ops);

                    const $nextEl = $(this.$el).next();
                    //Если селект открыт, принудительно и настойчиво обновить ОТОБРАЖАЕМЫЙ список, потому что сам он этого не сделает
                    if ($nextEl && $nextEl.hasClass('select2-container--open')) {
                        //Вопрос в том как это сделать лучшим образом
                        this.forcedClosing = true;
                        this.close();
                        this.forcedClosing = false;

                        this.forcedOpening = true;
                        this.open();
                        this.forcedOpening = false;
                    }
                }
                //////////////////////////////////////////////////////
            }
        },
        methods: {
            init() {
                var $this = $(this.$el);
                //if (!$this.hasClass('select2-hidden-accessible') || $this.hasClass('select2-reload')) {
                var parentFilterName = $this.attr('data-parentFilter') || null;
                var $select = $this.select2({
                    data: this.optionsArray,
                    ajax: this.url == null ? null : {
                        url: (params) => {
                            if (this.debug) console.log(this.url, params);
                            //if (typeof this.transport === 'Function' && !this.url)
                            //    return '';
                            return this.url;
                        },
                        dataType: 'json',
                        delay: 250,
                        data: this.ajaxData || (function (params) {
                            var parentFilter = null;
                            if (parentFilterName !== null) {
                                var parent = $('#filter-' + parentFilterName);
                                if (parent) {
                                    parentFilter = parent.val()
                                }
                            }
                            const page = params.page || 1;
                            if (page === 1) {
                                this.loadedOptionsByAjaxCount = 0;
                            }
                            return {
                                term: params.term,
                                q: params.term,
                                filter: {
                                    CaptionQuery: params.term
                                },
                                page: page,
                                parentFilter: parentFilter
                            };
                        }),
                        processResults: ((data, params) => {
                            let array = [];
                            let more = (data && data.pagination) ? data.pagination.more : null;
                            if (this.ajaxProcessResults) {
                                const processResults = this.ajaxProcessResults(data, params);
                                array = processResults.results;
                                more = processResults.pagination ? processResults.pagination.more : more;
                            }
                            else {
                                array = Array.isArray(data) ? data : data.results;
                            }

                            let results = array.map((d) => this.mapProp(d));
                            //this.loadedOptionsByAjaxCount += results.length;
                            //Поддержка paged result
                            //if (data.hasOwnProperty('total') && data.hasOwnProperty('data')) {
                            //    more = this.loadedOptionsByAjaxCount < data.total;
                            //}
                            params.page = params.page || 1;
                            return {
                                results: results,
                                pagination: {
                                    more: more
                                }
                            };
                        }),
                        cache: true,
                        transport: this.transport,
                        headers: this.ajaxHeaders
                    },
                    allowClear: this.allowClear,
                    maximumSelectionLength: this.maximumSelectionLength,
                    placeholder: this.placeholder,
                    disabled: this.disabled,
                    width: this.width,
                    dropdownAutoWidth: this.dropdownAutoWidth,
                    dropdownCssClass: this.dropdownCssClass,
                    //dropdownParent: ($this.closest('.modal').data('bs.modal') || {}).isShown ? $this.closest('.modal') : undefined,//???
                    minimumResultsForSearch: this.minimumResultsForSearch,//
                    minimumInputLength: this.minimumInputLength,
                    maximumInputLength: this.maximumInputLength,
                    language: {
                        searching: function() {
                            return "Поиск...";
                        }
                    },//this.language
                    matcher: this.matcher,
                    templateSelection: ((item, container, sd) => {
                        let result = '';
                        //console.log(item, container, sd)
                        //if ($(item).prop('_resultId')) {
                        var $select = $(this.$el);
                        var selectedValues = $select.val();

                        if (this.templateSelection) {
                            result = this.templateSelection(item, container, sd);
                        }

                        //Отрисовка "ЕЩЁ" и скрытие всех остальных элементов
                        //this.quantitySelectedToDisplay - отвечает за ,то сколько элементов будет отображено
                        if (this.multiple == true && !this.showAll) {
                            var selectedCount = this.selectedValues ? this.selectedValues.length : 0;
                            var selectedToNotDisplay = this.selectedValues.slice(this.quantitySelectedToDisplay, this.selectedValues.length);
                            if (selectedCount > this.quantitySelectedToDisplay && this.selectedValues[this.quantitySelectedToDisplay] === item.id) {
                                $(container).addClass('select-text-more');
                                result = result ? result : ("Еще " + (selectedCount - this.quantitySelectedToDisplay));
                            }
                            else if (selectedToNotDisplay.indexOf(item.id) !== -1) {
                                $(container).addClass('select-item-not-display');
                            }
                        }

                        if (this.allowClear == true && $select.closest('.input-group').length != 0 && !$select.closest('.input-group').hasClass('select-allow-clear')) {
                            $select.closest('.input-group').addClass('select-allow-clear')
                        }

                        return result || item.fulltext || item.text;
                    }),
                    templateResult: this.templateResult || ((node, container) => {
                        var $element = $(node.element);
                        var level = node.level || parseInt($element.data('level') || 0);
                        var $result = $('<div style="padding-left:' + (20 * level) + 'px;"></div>');
                        var $caption = $('<div>' + node.text + '</div>');
                        var subtext = node.subtext || $element.data('subtext') || '';
                        var subtextClass = node.subtextClass || $element.data('subtext-class') || '';
                        $result.append($caption);
                        if (subtext) {
                            $result.append($('<div class="' + subtextClass + '">' + subtext + '</div>'));
                        }
                        return $result;

                    }),
                    //(item) => {
                    //    //debugger;
                    //    return item.fulltext || item.text;
                    //},
                    //templateResult: (node, container) => {
                    //    return this.onTemplateResult(node, container);
                    //},
                    tags: this.tags,//insertTag,
                    createTag: this.createTag || this.createTagDefault,
                    insertTag:this.insertTag,
                    debug: this.debug
                });




                //Преобразуем объект
                let array = [];
                array = this.selectedValues;
                //if (typeof this.value === 'object' && this.value !== null) {
                //    if (this.value instanceof Array && this.value !== null) {
                //        this.value.forEach((item) => {
                //            if (typeof item === 'object' && item !== null) {
                //                array.push(item.id);
                //            }
                //            else if ((typeof item === 'string' || typeof item === 'number') && item !== null) {
                //                array.push(item);
                //            }
                //        });
                //    }
                //    else {
                //        array.push(this.value.id);
                //    }
                //}
                //else {
                //    array.push(this.value);
                //}
                ////////////////////////////////////////////////////////////////
                //УСТАНОВКА ЗНАЧЕНИЯ
                if (this.url !== null) {
                    ////console.log(2);
                    $this.val(array).trigger('change').trigger("select2:select");//this.value
                    $this.trigger({
                        type: 'select2:select',
                        params: {
                            data: array
                        }
                    });
                }
                else {
                    ////console.log(3);
                    $select.val(array).trigger('change');
                    let result = this.transformData();
                    if (result !== null) {
                        this.change(result);
                    }
                }

                //ОБРАБОТЧИКИ СОБЫТИЙ
                $select.on('select2:opening', (e) => {
                    if (!this.forcedOpening) {
                        this.onSelect2Opening(e);
                    }
                });
                $select.on('select2:open', (e) => {
                    if (!this.forcedOpening) {
                        this.onSelect2Open(e);
                    }
                });
                $select.on('select2:selecting', (e) => {
                    this.onSelect2Selecting(e);
                });
                $select.on('select2:unselecting', (e) => {
                    let $this = $(e.currentTarget);
                    this.onSelect2Unselecting($this, e);
                });
                $select.on('select2:select', (e) => {
                    let $this = $(e.currentTarget);
                    this.onSelect2Select($this, e);
                });
                $select.on('change', (e) => {
                    let $this = $(e.currentTarget);
                    this.onSelect2Change($this, e);
                });
                $select.on('select2:clearing', e => {
                    this.onSelect2Clearing(e);
                });
                $select.on('select2:clear', e => {
                    this.onSelect2Clear(e);
                })
                $select.on('select2:closing', e => {
                    if (!this.forcedClosing) {
                        this.onSelect2Closing(e);
                    }
                })
                $select.on('select2:close', e => {
                    if (!this.forcedClosing) {
                        this.onSelect2Close(e);
                    }
                })
                ///////////////////////////////////

                $this = null;

                return $select;
                //}
            },
            createTagDefault(params) {
                if (this.emailTags) {
                    // email check
                    const regex = /^[\w+\d+а-яА-ЯёЁ_-]+@[0-9a-zA-Zа-яА-ЯёЁ_-]+?\.[0-9a-zA-Zа-яА-ЯёЁ]+$/g;
                    const email = regex.exec(params.term);
                    if (email) {
                        return {
                            id: email[0],
                            text: email[0]
                        }
                    } else {
                        return null;
                    }
                }
                else {
                    return {
                        id: params.term,
                        text: params.term,
                        //newTag: true
                    }
                }
            },
            open() {
                $(this.$el).select2('open');
            },
            close() {
                $(this.$el).select2('close');
            },
            //renderMoreSelection() {},
            /**
             * Получает options из слота
             * @param slot
             */
            getOptionsFromSlot(slot) {
                let optionsArray = [];
                let voptions = slot.filter(d => d.tag === 'option');

                voptions.forEach((vnode) => {
                    if (vnode.data !== undefined && (vnode.data.attrs || vnode.data.domProps)) {
                        let attrs = vnode.data.attrs || {};
                        let domProps = vnode.data.domProps || {};
                        let op = {
                            id: attrs.value !== undefined ? attrs.value : domProps.value,
                            text: vnode.elm.label,
                        }
                        //Параметры select2
                        let level = attrs.level !== undefined ? attrs.level : domProps.level;
                        if (level) op.level = level;

                        //копирование пользовательских параметров prop-... (например prop-name-example в nameExample)
                        for (let name in attrs) {
                            if (typeof name === 'string' && name.startsWith('prop-')) {
                                //let prop = camelCase(
                                //    name.slice(5).split('/').pop().replace(/\.\w+$/, '')
                                //);
                                //snake-case в camelCase
                                let tempName = name.slice(5);
                                let prop = '';
                                for (let i = 0; i < tempName.length; i++) {
                                    if (tempName[i - 1] === '-')
                                        prop += tempName[i].toUpperCase();
                                    else
                                        prop += tempName[i];
                                }
                                prop = prop.replace(/-/g, '');
                                if (!op.props) op.props = {};
                                op.props[prop] = attrs[name];
                            }
                        }
                        //if (vnode.data.attrs.selected) op.selected = true;4607vnode.data.attrs.value
                        optionsArray.push(op);
                    }
                });
                return optionsArray;
            },
            onBeforeInit() {
                this.$emit('beforeInit');
            },
            onInit($select) {
                this.$emit('init', $select);
            },
            /*
             * Меняем options, если изменились входной массив options, но не slots, в select2
             * */
            setOptionsFromProp(options) {
                let getOption = (option) => {
                    //let text = option[this.mapPropToText];//.text;
                    //let id = option[this.mapPropToId];//.id
                    const result = this.mapProp(option);
                    //this.optionsArray.push({
                    //    id: id,
                    //    text: text
                    //});
                    //var newOption = new Option(text, id, false, false);
                    ////console.log(4);
                    return `<option value="${result.id}"\
                                    data-level="${result.level}"\
                                    data-subtext="${result.subtext}"\
                                    data-subtext-class="${result.subtextClass}">\
                                ${result.text}\
                            </option>`;
                }
                if (options) {
                    $(this.$el).find('optgroup').detach();
                    $(this.$el).find('option').detach();
                    options.forEach((option) => {
                        //это optgroup
                        if (option[this.mapPropToChildren]) {
                            let optgroup = option;
                            let label = optgroup.label;
                            let optionsChildrenForRender = '';
                            optgroup[this.mapPropToChildren].forEach((child) => {
                                optionsChildrenForRender += getOption(child);
                            });
                            $(this.$el).append(`<optgroup label="${label}" >${optionsChildrenForRender}</optgroup>`);
                        }
                        else {
                            if (option[this.mapPropToId] !== undefined) {
                                let optionForRender = getOption(option);
                                $(this.$el).append(optionForRender);//
                            }
                        }
                    });
                    let array = this.selectedValues;
                    ////console.log(5);
                    $(this.$el).val(array).trigger('change');

                    //$(this.$el).empty().select2({ data: options });
                }
            },
            mapProp(item) {
                let result = {}
                if (typeof this.mapPropToText === 'object')
                    result.text = this.parseTemplate(this.mapPropToText.template, this.mapPropToText.props, item);
                else
                    result.text = item[this.mapPropToText] || '';

                if (typeof this.mapPropToSubtext === 'object') {
                    result.subtext = this.parseTemplate(this.mapPropToSubtext.template, this.mapPropToSubtext.props, item);
                    result.subtextClass = this.mapPropToSubtext.classes || '';
                }
                else {
                    result.subtext = item[this.mapPropToSubtext] || '';
                    result.subtextClass = 'text-muted';
                }

                if (typeof this.mapPropToId === 'object')
                    result.id = this.parseTemplate(this.mapPropToId.template, this.mapPropToId.props, item);
                else
                    result.id = item[this.mapPropToId];

                if (item[this.mapPropToLevel] !== undefined && item[this.mapPropToLevel] !== null)
                    result.level = item[this.mapPropToLevel];
                if (item[this.mapPropToSelected] !== undefined && item[this.mapPropToSelected] !== null)
                    result.selected = item[this.mapPropToSelected];
                if (item[this.mapPropToDisabled] !== undefined && item[this.mapPropToDisabled] !== null)
                    result.disabled = item[this.mapPropToDisabled];
                if (item[this.mapPropToChildren] !== undefined && item[this.mapPropToChildren] !== null)
                    result.children = item[this.mapPropToChildren];

                result.data = item['data'];
                //return

                //        id: d[this.mapPropToId],
                //        text: d[this.mapPropToText],

                //    if (typeof this.mapPropToText === 'object')
                //        result.text = this.parseTemplate(this.mapPropToText.template, this.mapPropToText.props, d);

                //    if (typeof this.mapPropToId === 'object')
                //        result.id = this.parseTemplate(this.mapPropToId.template, this.mapPropToId.props, d);


                    return result;
            },
            parseTemplate(templateStr, propsForInsert, option) {
                for (var i = 0; i < propsForInsert.length; i++) {
                    const value = option[propsForInsert[i]];
                    if(value)
                        templateStr = templateStr.replace(`{{${propsForInsert[i]}}}`, value);
                    else
                        templateStr = ''
                }
                return templateStr;
            },
            getFilterAttrs(options) {
                //оставляем только выбранные свойства//params исключение
                //this.allowedAttrs - Указывает какие свойства оставить
                let ops = [];
                if (options !== null && this.allowedAttrs !== null) {
                    options.forEach((option, i_option) => {
                        let obj = {};
                        this.allowedAttrs.forEach((attr, i_attr) => {
                            if (typeof attr === 'string' && attr !== 'params') {
                                obj[attr] = option[attr];
                            }
                        });//
                        obj.params = option.params;
                        ops.push(obj);
                    });
                }
                else {
                    ops = options;
                }
                this.optionsArray = ops;
                return ops;
            },
            transformData() {
                let result = null;
                //преобразуем массив выбранных элементов в объекты === 'array'
                if (this.optionsArray !== null) {
                    result = [];
                    let vals = $(this.$el).val();
                    if (this.allowTransformData) {

                        if (typeof vals === 'string') {
                            let index = this.optionsArray.findIndex((option, index) => {
                                return option.id === vals;
                            });
                            if (index !== -1) {
                                result = this.optionsArray[index];
                            }
                        }
                        else if (Array.isArray(vals)) {
                            vals.forEach((id, i) => {
                                let index = this.optionsArray.findIndex((option, index) => {
                                    return option.id === id;
                                });
                                if (index !== -1) {
                                    result.push(this.optionsArray[index]);
                                }
                            });
                        }
                    }
                    else {
                        result = vals === '' ? null : vals;
                    }

                }
                return result;
            },
            getSelectedValues(value) {
                const array = [];
                if (typeof value === 'object' && value !== null) {
                    if (value instanceof Array) {
                        value.forEach((item) => {
                            if (typeof item === 'object' && item !== null) {
                                array.push(`${item.id}`);
                            }
                            else {//if (typeof item === 'string' && item !== null)
                                array.push(`${item}`);
                            }
                        });
                    }
                    else {
                        array.push(`${value.id}`);
                    }
                }
                else if(value !== null){
                        array.push(`${value}`);
                }
                return array;
            },
            change(value) {
                this.$emit('input', value);
            },
            onSelect2Change($this, e) {


                let vals = $this.val();
                let result = null;


                result = vals === '' ? null : vals;
                if (this.url === null) {
                    result = this.transformData();
                }
                this.change(result);
                //console.log('fin');




                ///////////////////////////////////
                var $input = $this.parent().find('.select2-search__field');
                //if (this.multiple === true) {
                //    $input.on('keydown', (e) => {
                //        if (e.keyCode == 8 && $input.val() == "")
                //            e.stopPropagation();
                //    });
                //    //removeTagDistinct($this[0]);
                //};
                ////Чтобы input был 100% (виден изначально)
                var $inputparent = $this.parent().find('.select2-search--inline');
                //$inputparent.css('width', '100%');
                //$input.css('width', '100%');
                if (!$this.val()) {
                    $inputparent.css('width', '100%');
                    $input.css('width', '100%');
                } else {
                    $inputparent.css('width', 'auto');

                }
                ///////////////////////////////////
                this.$emit('change', e);
            },
            onSelect2Open(e) {
                this.$emit('open', e);
            },
            onSelect2Opening(e) {
                this.$emit('opening', e);
            },
            onSelect2Select($this, e) {
                this.$emit('select', e);
            },
            onSelect2Unselecting($this, e) {
                this.$emit('unselecting', e);
            },
            onSelect2Selecting(e) {
                this.$emit('selecting', e);
            },
            onSelect2Clearing(e) {
                this.$emit('clearing', e);
            },
            onSelect2Clear(e) {
                this.$emit('clear', e);
            },
            onSelect2Closing(e) {
                this.$emit('closing', e);
            },
            onSelect2Close(e) {
                this.$emit('close', e);
            },
            //onTemplateSelection(item) {

            //    this.$emit('template-selection', item);

            //},
            //onTemplateResult(state) {
            //    this.$emit('template-result', state);
            //}
        },
        components: {
            //vSelect
        }
    }


</script>
<style>
    .vue-select .select2-selection--single {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .vue-select +
    .select2 input {
        width: 100% !important;
        height: 24px;
    }

    .v-select.dropdown {
        /*transition: none !important;
        transform: none !important;
        opacity: 1 !important;
        display: block !important;*/
    }

    .v-select {
        /*transition: none;
        transform: none;
        opacity: 1;
        display: block;*/
    }

    /*Для multiple select Насртойка отображения элемента "Ещё" и идущих после него*/
    .select2-selection--multiple .select2-selection__rendered > li.select2-selection__choice.select-item-not-display .select2-selection__choice__remove,
    .select2-selection--multiple .select2-selection__rendered > li.select2-selection__choice.select-item-not-display,
    .select2-selection--multiple li.select2-selection__choice.select-item-not-display .select2-selection__choice__remove {
        display: none;
    }

    .select2-selection--multiple li.select2-selection__choice.select-text-more {
        background-color: transparent;
        border: 1px solid transparent;
        padding-right: 0px;
    }

        .select2-selection--multiple .select2-selection__rendered > li.select2-selection__choice.select-text-more .select2-selection__choice__remove,
        .select2-selection--multiple li.select2-selection__choice.select-text-more .select2-selection__choice__remove {
            display: none;
        }
</style>
