const state = {
    profile: null
};

const getters = {
    profile: (state) => {
        return state.profile;
    }
};

export { state, getters };