/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuelidate from 'vuelidate';
import store from "./store";

import Argon from "./plugins/argon-kit";
//import Axios from 'axios';
import './registerServiceWorker';
import ApiService from './services/api.service';
import TokenService from './services/token.service';
import VueNotifications from 'vue-notification';
import { FormSelectPlugin } from 'bootstrap-vue';


Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(VueNotifications);

Vue.use(Vuelidate);
Vue.use(FormSelectPlugin);
console.log(process.env.VUE_APP_API_BASE_URL);
ApiService.init(process.env.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL_LOCAL);
ApiService.setHeader('content-type', 'application/json');



// If token exists set header
if (TokenService.getToken()) {
    ApiService.setAuthHeader();
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
