<template>
    <section class="recover section section-shaped section-lg my-0">
        <div class="shape shape-style-1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <img src="../assets/img/logo_in_login.svg" class="mb-5" />
                    <card
                        type="secondary"
                        shadow
                        header-classes="bg-white pb-5"
                        body-classes="px-lg-4 py-lg-4"
                        class="border-0"
                    >
                        <h5 class="color-black text-center text-muted mb-4">
                            Смена пароля
                        </h5>
                        <form role="form">
                            <div class="form-group">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                                <base-input
                                    v-model="password"
                                    v-on:keyup.enter="changePassword"
                                    class="mb-2"
                                    alternative
                                    type="password"
                                    name="password"
                                    required
                                    autocomplete="password"
                                    placeholder="Новый пароль"
                                >
                                </base-input>
                                <span
                                    class="small text-danger"
                                    v-if="$v.password.$error"
                                >
                                    Укажите пароль от 6 символов
                                </span>
                            </div>
                            <div class="form-group">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                                <base-input
                                    v-model="repeatPassword"
                                    v-on:keyup.enter="changePassword"
                                    class="mb-2"
                                    alternative
                                    type="password"
                                    name="repeatPassword"
                                    required
                                    autocomplete="password"
                                    placeholder="Повтор пароля"
                                >
                                </base-input>
                                <span
                                    class="small text-danger"
                                    v-if="$v.repeatPassword.$error"
                                >
                                    Пароли должны совпадать
                                </span>
                            </div>
                            <div class="text-center">
                                <base-button
                                    @click="changePassword"
                                    :disabled="submitStatus !== null"
                                    type="danger"
                                    class="mb-4"
                                    >Сохранить</base-button
                                >
                                <p
                                    class="typo__p text-danger"
                                    v-if="submitStatus === 'ERROR'"
                                >
                                    Похоже что ссылка, по которой вы перешли на эту страницу, устарела. Пройдите процедуру сброса пароля заново, либо обратитесь в службу поддержки пользователей.
                                </p>
                                <p
                                    class="typo__p text-result"
                                    v-if="submitStatus === 'SUCCESS'"
                                >
                                    Пароль успешно изменён. В течение 3 секунд вы будете переадресованы на страницу входа на портал.
                                </p>
                            </div>
                        </form>
                    </card>
                    <div class="recover-footer">
                        <div class="mr-3 text-left">
                            <a
                                href="https://id2.action-media.ru/l/politika_pd?id=11"
                                target="_blank"
                                class="text-light"
                            >
                                <span class="fs-11"
                                    >Политика обработки персональных
                                    данных</span
                                >
                            </a>
                        </div>
                        <div class="mr-3">
                            <a
                                href="https://xsud.ru/help2/about-2"
                                target="_blank"
                                class="text-light"
                            >
                                <span class="fs-11">Подробнее о системе</span>
                            </a>
                        </div>
                        <div class="mr-3 text-right">
                            <a
                                href="https://xsud.ru/help2"
                                target="_blank"
                                class="text-light"
                            >
                                <span class="fs-11">Справка</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.recover .container {
    text-align: center;
}

.recover .color-black {
    color: #222222 !important;
}

.recover .bg-secondary {
    background-color: #fff !important;
}

.form-group i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.form-group i.fa-envelope-o {
    margin-left: -5px;
}

.form-group .input-group-alternative {
    display: inline-block;
    width: 270px;
}

.form-group.input-group-alternative {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    width: calc(100% - 30px);
}

.form-group.form-group--error {
    border-color: firebrick;
}

.form-group.input-group:focus {
    /*???*/
    border-bottom: 1px solid #222222;
}

.form-group.input-group-alternative input {
    padding-left: 0px;
}

.recover .form-group {
    text-align: left;
}

.recover .form-group .text-danger {
    margin-left: 29px;
}

.recover .text-result {
    color: #065095;
}

.form-footer {
    position: relative;
}

.form-footer::before,
.form-footer::after {
    content: "";
    display: block;
    height: 1px;
    width: calc(50% - 25px);
    background: #d2d2d2;
    margin: 16px 0 0 20px;
    position: absolute;
    top: -3px;
}

.form-footer::before {
    left: -16px;
}

.form-footer::after {
    right: 3px;
}

.recover-footer {
    font-size: 12px;
    color: #828282;
    margin-top: 134px;
    margin-right: -5px !important;
    margin-right: 0px;
}

.recover-footer div {
    padding: 0px;
    display: inline-block;
}

@media (min-width: 1200px) {
    .recover .container {
        max-width: 1040px;
    }
}

@media (max-width: 991px) {
    .recover {
        padding-top: 0px !important;
    }
}
</style>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import UserService from '../services/user.service';

export default {
    components: {},
    data: () => ({
        password: null,
        repeatPassword: null,
        submitStatus: null,
    }),
    watch: {},
    mounted: function () {
        if (!this.$route.query.token || !this.$route.query.userId) {
            this.gohome();
        }
    },
    methods: {
        setStatus(status) {
            this.submitStatus = status;
        },
        gohome: function () {
            this.$router.push("login");
        },
        changePassword: async function () {
            this.$v.$touch();
            if (!this.$v.$invalid && !!this.$route.query.token && !!this.$route.query.userId) {
                this.setStatus("PENDING");
                const saveResponse = await UserService.savePassword(this.$route.query.userId, this.$route.query.token, this.password);
                this.setStatus(saveResponse.data &&  saveResponse.data.succeeded ? "SUCCESS" : "ERROR");
                setInterval(() => {
                    this.gohome();
                }, 3000);
            }
        },
    },
    validations: {
        password: {
            required,
            minLength: minLength(6),
        },
        repeatPassword: {
            sameAsPassword: sameAs("password"),
        },
    },
};
</script>