import '@/assets/scss/vendor/select2/index.scss';
import '@/assets/vendor/daterangepicker/index.css';
import '@/assets/vendor/bootstrap4-datetimepicker/css/bootstrap-datetimepicker.min.css';

import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/vendor/font-awesome/css/font-awesome.css";
import "@/assets/scss/argon.scss";

// import $ from 'jquery';
import 'select2/dist/js/select2.full.min.js';
// import 'select2/dist/js/i18n/ru.js';
import '@/assets/vendor/daterangepicker/index.js';
import '@/assets/vendor/bootstrap4-datetimepicker/js/bootstrap-datetimepicker.min.js';
import globalComponents from "./globalComponents.js";
import globalDirectives from "./globalDirectives.js";
import globalFilters from "./globalFilters.js";
import VueLazyload from "vue-lazyload";

// window.jQuery = $;

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(globalFilters);
    Vue.use(VueLazyload);
  }
};
