<template>
    <div class="form-group">
      <label class="mb-0 text-muted">{{attribute.caption}}</label>
        <div v-if="attributeSchema.type == ValueType.STRING || attributeSchema.type == ValueType.TEXT">
            <div style="white-space: break-spaces">{{displayValue}}</div>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.INT">
            {{displayValue}}
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DOUBLE">
            {{displayValue}}
        </div>
        <div v-else-if="attributeSchema.type == ValueType.BOOL">
            <base-checkbox alternative
                            :checked="displayValue"
                            disabled>

            </base-checkbox>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DICTIONARY">
          <div v-for="(item, i) of displayValue" :key="i">
              {{item}}
          </div>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DATE || attributeSchema.type == ValueType.DATETIME">
            {{displayValue}}
        </div>
    </div>
</template>
<script>
import { ValueType } from "@/models/base";
import moment from 'moment';
import { getFormattedDate, getFormattedDatetime } from '@/utils';

export default {
  name: "vue-extended-attribute-display",
  components: {
    
  },
  props: {
    attribute: {
      type: Object,
      required: true
    },
    attributeSchema: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    };
  },
  computed:{
    ValueType(){
      return ValueType;
    },
    displayValue() {
      let result = this.attributeSchema.valueByDefault || '-';
      if(this.attribute.value){
        switch(this.attributeSchema.type){
          case ValueType.STRING:
          case ValueType.TEXT:
            result = this.attribute.value;
            break;
          case ValueType.INT:
            result = this.attribute.value;
            break;
          case ValueType.DOUBLE:
            var num = Number(this.attribute.value);
            result = num.toFixed(2);
            break;
          case ValueType.DICTIONARY:
            result = this.attribute.description ? this.attribute.description.split(',') : '';
            break;
          case ValueType.DATE:
            result = getFormattedDate(this.attribute.value);
            break;
          case ValueType.DATETIME:
            result = getFormattedDatetime(this.attribute.value);
            break;
          case ValueType.BOOL:
            result = this.attribute.value === 'True';
            break;
        
        }
      }
      return result;
    }
  },
  methods: {
    
  }
};
</script>
<style>
</style>
