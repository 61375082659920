import moment from 'moment';
import getPluralizedString from './getPluralizedString.js';

/**
 * 
 * @param {any} dateStr
 * @param {number} countSecondsForDefault колличество секунд до истечения которых отображается текст по умолчанию
 * @param {string} textForDefault текст по умолчанию
 */
export default function (dateStr, countSecondsForDefault, textForDefault) {
    
    let result = '';
    if (dateStr) {
        let date = new Date(dateStr);
        let back = "";
        let dateNow = new Date();
        let diff = Math.abs(dateNow.getTime() - date.getTime());

        if (countSecondsForDefault && diff < countSecondsForDefault * 1000) {
            back = textForDefault || "Только что";
        }
        else if (diff < (60 * 1000)) {
            let sec = Math.ceil(diff / 1000);
            back += sec;
            back += getPluralizedString(sec, " секунду", " секунды", " секунд");
            back += " назад";

        }
        else if (diff >= (60 * 1000) & diff < (3600 * 1000)) {
            let min = Math.ceil(diff / (1000 * 60));
            back += min;
            back += getPluralizedString(min, " минуту", " минуты", " минут");
            back += " назад";
        }
        else if (diff >= (3600 * 1000) & diff < (24 * 3600 * 1000)) {
            let hour = Math.ceil(diff / (1000 * 3600));
            back += hour;
            back += getPluralizedString(hour, " час", " часа", " часов");
            back += " назад";
        }
        else if (diff >= (24 * 3600 * 1000) & diff < (7 * 24 * 3600 * 1000)) {
            let day = Math.ceil(diff / (1000 * 3600 * 24));
            back += day;
            back += getPluralizedString(day, " день", " дня", " дней");
            back += " назад";

        }
        else if (diff >= (7 * 24 * 3600 * 1000)) {
            back += moment(date).format('DD.MM.YYYY');

        }
        result = back;
    }
    return result;
}
