const state = {
    tickets: [],
    newTicketId: null,
    ticketsTotalCount: 0,
    page: 0,
    take: 10,
    ticketTypes: []
};

const getters = {
    take: (state) => {
        return state.take;
    }
};

export { state, getters };
