<template fuctional>
    <div style="height:10em">
        <div class="vue-ajax-loading-animation vue-loader" style="height:100px"></div>
    </div>
</template>
<script>
    
    export default {
        name:'vue-loader',
        props: {
            //'placeholder': {
            //    type: String,
            //    default: 'Выберите значение'
            //},
        },
    }


</script>
<style scoped>
    .vue-ajax-loading-animation {
        font-size: 21px;
        font-weight: 100;
    }

    /* =================== LOADER =================== */
    .vue-loader,
    .vue-loader:before,
    .vue-loader:after {
        background: #db1212;
        -webkit-animation: load1 1s infinite ease-in-out;
        animation: load1 1s infinite ease-in-out;
        width: 1em;
        height: 4em;
    }

    .vue-loader {
        color: #db1212;
        text-indent: -9999em;
        margin: 88px auto;
        position: relative;
        font-size: 13px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    .vue-loader:before,
    .vue-loader:after {
        position: absolute;
        top: 0;
        content: '';
    }

    .vue-loader:before {
        left: -1.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .vue-loader:after {
        left: 1.5em;
    }

    @-webkit-keyframes load1 {
        0%, 80%, 100% {
            box-shadow: 0 0;
            height: 4em;
        }

        40% {
            box-shadow: 0 -2em;
            height: 5em;
        }
    }

    @keyframes load1 {
        0%, 80%, 100% {
            box-shadow: 0 0;
            height: 4em;
        }

        40% {
            box-shadow: 0 -2em;
            height: 5em;
        }
    }
    /* =================== END OF LOADER =================== */
</style>
