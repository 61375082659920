const state = {
    organization: null
};

const getters = {
    ticketAttributeSchema: (state) => {
        return state.organization 
            && state.organization.schema
            && state.organization.schema.ticketAttributeSchema;
    }
};

export { state, getters };