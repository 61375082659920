<template function>
    <div class="notfound-container">
        <div>
            <div class="notfound-img text-danger" style="">404</div>
            <p class="text-center">Запрошенная страница не найдена</p>
            <div>
                <a class="btn btn-neutral" @click="$router.go(-1)">Назад</a>
                <router-link to="/" class="btn btn-neutral pull-right">На главную</router-link>

            </div>
            
        </div>
    </div>
</template>
<script>
    export default {
        created() {

        },
        methods: {

        },
        components: {

        }
    };
</script>
<style>
    .notfound-container{
        display:flex;
        justify-content:center;
        align-items:center;
        height:100%;
            padding: 10vh 0;
    }

    .notfound-img{
        font-size:200px;line-height: 1em;
    }
</style>
