export default {
    UpdateTickets(state, { tickets, withCleaning = true }) {
        if (withCleaning)
            state.tickets = tickets;
        else
            state.tickets.push(...tickets);
    },
    UpdateTicket(state, ticket) {
        let index = state.tickets.findIndex(t => t.id === ticket.id);
        if (index)
            state.tickets.splice(index, 1, ticket);
        else
            state.tickets.unshift(ticket);
    },
    AddTicket(state, ticket) {
        state.tickets.unshift(ticket);
    },
    DeleteTicket(state, id) {
        let index = state.tickets.findIndex(t => t.id === id);
        state.tickets.splice(index, 1);
    },
    UpdateNewTicketId(state, id) {
        state.newTicketId = id;
    },
    UpdateTicketsTotalCount(state, count) {
        state.ticketsTotalCount = count;
    },
    IncrementPage(state) {
        state.page++;
    },
    DecrementPage(state) {
        state.page--;
    },
    ResetPageTo(state, num) {
        state.page = num;
    },
    UpdateTicketTypes(state, types) {
        state.ticketTypes = types;
    }
};
