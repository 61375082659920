<template>
    <div class="list-page">
        <section id="tickets-section"
                 v-infinite-scroll="addMore" :infinite-scroll-disabled="loading" infinite-scroll-distance="0">
            <div class="container ">
                <div class="list-filter">
                    
                    <div class="row">
                        <div class="col-md-3">
                            <router-link to="/tickets/create" class="btn btn-danger my-4">
                                Создать обращение
                            </router-link>
                        </div>
                        <div class="col-md-3 col-sm-6 my-4">
                            <label>Период</label>
                            <vue-datetimepicker-range :startvalue.sync="filter.createdDateStart"
                                                      :endvalue.sync="filter.createdDateEnd"
                                                      supportedFormat="YYYY-MM-DDTHH:mm:ssZ">
                            </vue-datetimepicker-range>
                        </div>
                        <div class="col-md-3 col-sm-6 my-4">
                            <label>Тип обращения</label>
                            <select is="vue-select-pure" 
                                    v-model="filter.type" 
                                    placeholder="Тип обращения"
                                    allow-clear
                                    :options="ticketTypesForSelect">
                            </select>
                        </div>
                        <div class="col-md-3 col-sm-6 my-4">
                            <label>Статус</label>                           
                            <select is="vue-select-pure" placeholder="Статус"
                                    allow-clear
                                    v-model="filter.status">
                                <option value="">Не выбран</option>
                                <option value="0">Новый</option>
                                <option value="1">В работе</option>
                                <option value="2">Закрыт</option>
                            </select>
                        </div>
                        <!--<div v-for="schemaAttribute of schemaAttributeFilters"
                            class="col-md-3 col-sm-6 my-4">
                            <label>{{schemaAttribute.caption}}</label>
                            <vue-extended-attribute-filter-input :value="filter.extendedAttributes.find(f => f.extendedAttributeName === schemaAttribute.name)"
                                                                    @input="setExtendedAttributeFilterValues">
                            </vue-extended-attribute-filter-input>
                        </div>-->
                    </div>
                </div>
                <card shadow class="ticket-list" no-body v-if="ticketsTotalCount != 0">
                    <!--<div class="px-3">-->
                        <vue-ticket-list-item v-for="(ticket, key) in tickets"
                                              :ticket="ticket"
                                              :key="ticket.id">
                        </vue-ticket-list-item>
                    <!--</div>-->
                </card>
                <div class="empty-block" v-else>
                    <span>Обращения отсутствуют</span>
                </div>
                <div v-if="loading" class="loading-block">
                    <div>
                        <vue-loader></vue-loader>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
    import VueTicketListItem from './components/TicketListItem.vue';
    //import VueExtendedAttributeFilterInput from '@/components/VueExtendedAttributeFilterInput.vue';

    export default {
        data: function () {
            return {
                loading: false,
                filter: {
                    createdDateStart: null,
                    createdDateEnd: null,
                    status: null,
                    type:null,
                    extendedAttributes: []
                },
            }
        },
        computed: {
            ...mapState('ticketModule', {
                'tickets': state => state.tickets,
                'ticketsTotalCount': state => state.ticketsTotalCount,
                'page': state => state.page,
                'ticketTypes': state => state.ticketTypes,
            }),
            ...mapGetters('organizationModule', {
                'ticketAttributeSchema': 'ticketAttributeSchema',
            }),
            schemaAttributes(){
                return this.ticketAttributeSchema && this.ticketAttributeSchema.attributes;
            },
            ticketTypesForSelect() {
                return this.ticketTypes.map(str => ({
                    id: str,
                    text: str
                }));
            }
        },
        watch: {
            'filter.createdDateStart'(value) {
                this.search();
            },
            'filter.createdDateEnd'(value){
                this.search();
            },
            'filter.status'(value){
                this.search();
            },
            'filter.type'(value){
                this.search();
            },
        },
        async created() {
            try {
                this.search(true);
            } catch (error) {
                window.console.log(error);
            }
        },
        mounted() {
        },
        destroyed() {
        },
        methods: {
            ...mapActions('ticketModule', [
                'GET_TICKETS',
                'SAVE_TICKET',
                'ADD_TICKET',
                'DELETE_TICKET',
            ]),
            addMore() {
                if (this.tickets.length < this.ticketsTotalCount) {
                    this.search(false);
                }
            },
            async search(isStart = true) {
                if (!this.loading) {
                    this.loading = true;
                    let requestedPage = isStart ? 1 : this.page + 1;
                    await this.GET_TICKETS({ page: requestedPage, withCleaning: isStart, filter: this.filter });
                    this.loading = false;
                }
            }
        },
        components: {
            VueTicketListItem,
            //VueExtendedAttributeFilterInput
        }
    };
</script>
<style>
    .loading-block {
        height: 100px;
        text-align: center
    }

    .list-page .empty-block {
        color: #dbdbdb;
        font-size: 35px;
        text-align: center;
        margin: 0 auto;
        padding-top: 85px;
        padding-bottom: 20px;
    }

    @media (max-width: 991px){
        .header-global .container,
        .list-page .container {
            max-width: initial;
        }
    }


    

</style>
