<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <!--<section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">
                                    A beautiful Design System
                                    <span>completed with examples</span>
                                </h1>
                                <p class="lead  text-white">
                                    The design system comes with four pre-built pages to help
                                    you get started faster. You can change the text and images and you're good to
                                    go.
                                </p>
                                <div class="btn-wrapper">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>-->
            <section class="section-hero section-shaped my-0">
                <div class="shape shape-style-1 shape-primary">
                    <span class="span-150"></span>
                    <span class="span-50"></span>
                    <span class="span-50"></span>
                    <span class="span-75"></span>
                    <span class="span-100"></span>
                    <span class="span-75"></span>
                    <span class="span-50"></span>
                    <span class="span-100"></span>
                    <span class="span-50"></span>
                    <span class="span-100"></span>
                </div>
                <div class="container shape-container d-flex align-items-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-7 text-center pt-lg">
                                <img src="img/brand/white.png" style="width: 200px;" class="img-fluid">
                                <p class="lead text-white mt-4 mb-5">A beautiful Design System for Bootstrap 4. It's Free and Open Source.</p>
                                <div class="btn-wrapper">
                                    <router-link to="/components"
                                                 class="btn btn-default mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="fa fa-code">
                                        Посмотреть компоненты
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row align-items-center justify-content-around stars-and-coded">
                            <div class="col-sm-4">
                                <span class="text-white alpha-7 ml-3">Star us on</span>
                                <a href="https://github.com/creativetimofficial/argon-design-system" target="_blank" title="Support us on Github">
                                    <img src="img/brand/github-white-slim.png" style="height: 22px; margin-top: -3px">
                                </a>
                            </div>
                            <div class="col-sm-4 mt-4 mt-sm-0 text-right">
                                <span class="text-white alpha-7">Coded by</span>
                                <a href="https://www.creative-tim.com" target="_blank" title="Creative Tim - Premium Bootstrap Themes and Templates">
                                    <img src="img/brand/creativetim-white-slim.png" class="ml-3" style="height: 30px;">
                                </a>
                            </div>
                        </div>-->
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>

    </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
