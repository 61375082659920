import ApiService from './api.service';

export default class TicketService {

    static async changeOrganization(orgId) {
        ApiService.setHeader('OrganizationId', orgId);
    }

    /**
     * Получает список тикетов
     * @param {number} skip
     * @param {number} take
     * @returns {object} { tickets, totalCount }
     */
    static async getTickets(skip = 0, take = 10, filter = null) {
        let argsPath = [skip, take].join('/');
        const response = await ApiService.get(`/tickets/${argsPath}`, {
            params: filter
        });
        return response.data;
    }

    /**
     * Получает тикет по id
     * @param {string} id
     */
    static async getTicket(id) {
        const response = await ApiService.get(`/tickets/${id}`);
        return response.data;
    }

    static async saveTicket(ticket) {
        let param = ticket ? JSON.stringify(ticket) : null;
        const response = await ApiService.put(`/tickets`, param);
        return response.data;
    }

    static async addTicket(ticket) {
        let param = ticket ? JSON.stringify(ticket) : null;
        const response = await ApiService.post(`/tickets`, param);
        return response.data;
    }

    /**
     *
     * @param {string} id
     */
    static async deleteTicket(id) {
        const response = await ApiService.delete(`/tickets/${id}`);
        return response.data;
    }

    /**
     * 
     * @param {any} ticketId
     */
    static async getComments(ticketId, skip = 0, take = 10) {
        let argsPath = [skip, take].filter(p => p !== null).join('/');
        const response = await ApiService.get(`/tickets/${ticketId}/comments/${argsPath}`);
        return response.data;
    }

    /**
     * 
     * @param {any} ticketId
     * @param {any} id
     */
    static async getComment(ticketId, id) {
        const response = await ApiService.get(`/tickets/${ticketId}/comments/${id}`);
        return response.data;
    }

    static async saveComment(ticketId, comment) {
        const response = await ApiService.post(`/tickets/${ticketId}/comments`, JSON.stringify(comment));
        return response.data;
    }

    static async addComment(ticketId, comment) {
        const response = await ApiService.put(`/tickets/${ticketId}/comments`, JSON.stringify(comment));
        return response.data;
    }

    static async deleteComment(ticketId, id) {
        const response = await ApiService.delete(`/tickets/${ticketId}/comments/${id}`);
        return response.data;
    }

    /**
     * 
     * @param {any} ticketId
     */
    static async getAttachments(ticketId, skip = 0, take = 10) {
        const response = await ApiService.get(`/tickets/${ticketId}/files/${skip}/${take}`);
        return response.data;
    }

    /**
     * 
     * @param {any} ticketId
     * @param {any} id
     */
    static async getAttachment(ticketId, id) {
        const response = await ApiService.get(`/tickets/${ticketId}/files/${id}`);
        return response.data;
    }

    static async downloadAttachment(id) {
        const fileInfo = await ApiService.get(`/files/${id}`);
        const response = await ApiService.get(`/files/download/${id}`, { responseType: 'arraybuffer' });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileInfo.data.originalFileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        return response.data;
    }

    static async saveAttachment(ticketId, comment) {
        const response = await ApiService.post(`/tickets/${ticketId}/files`, JSON.stringify(comment));
        return response.data;
    }

    static async addAttachment(ticketId, comment) {
        const response = await ApiService.put(`/tickets/${ticketId}/files`, JSON.stringify(comment));
        return response.data;
    }

    static async deleteAttachment(ticketId, id) {
        const response = await ApiService.delete(`/tickets/${ticketId}/files/${id}`);
        return response.data;
    }

    static async getExtendedAttributes(ticketId) {
        const response = await ApiService.get(`/tickets/${ticketId}/extended-attributes`);
        return response.data;
    }

    static async getExtendedAttributeSchema() {
        const response = await ApiService.get(`/tickets/attribute-schema`);
        return response.data;
    }

    static async getTicketTypes(){
        const response = await ApiService.get(`/tickets/types`);
        return response.data;
    }
}