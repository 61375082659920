<template>
    <div class="input-group vue-datetimepicker datetimepicker-init">
        <input type="text"
               class="form-control"
               :placeholder="placeholder"
               :value="date ? date.format(formatDisplay) : null" />
        <span class="datetimepicker-addon input-group-addon">
            <img :src="icon" />
        </span>
    </div>
</template>
<script>
    import $ from 'jquery';
    import moment from 'moment';
    //import momentTimeZone from 'moment-timezone';

    export default {
        //name: 'datetimepicker',
        props: {
            //'supportedFormat': {
            //    type: String,
            //    default: 'YYYY-MM-DDTHH:mm:ssZ'
            //},
            'inputFormat': {
                type: String,
                //default: 'YYYY-MM-DDTHH:mm:ssZ'
            },
            'outputFormat': {
                type: String,
                default: 'YYYY-MM-DDTHH:mm:ssZ'
            },
            'dateFormat': {
                type: String,
                default: 'DD.MM.YYYY HH:mm'
            },
            'dateFormatAllDay': {
                type: String,
                default: 'DD.MM.YYYY'
            },
            'placeholder': {
                type: String,
                default: 'Выберите дату'
            },
            'icon': {
                type: String,
                default: '/img/svg/datetimepicker.svg'
            },
            'locale': {
                type: String,
                default: 'ru'
            },
            /**
             * Указывает на, то является ли значение
             * */
            'isAllDay': {
                type: Boolean,
                default: false
            },
            'allDayTime': {
                type: String,
                default: '00:00',
                validator(value) {
                    let result = value.search(/^\d{2}:\d{2}$/);
                    return result !== -1 ? true : false;
                }
            },
            'value': {
                type: String,
                default: null,
                validator(value) {
                    //let result = value.search(/^((\+|\-|)\d{2}:\d{2}(:\d{2}|))$/);
                    //return result !== -1 ? true : false;
                    //debugger;
                    return true;
                }
            },
            /**
             * 
             * */
            'minValue': {
                type: String,
                default: null,
                //validator(value) {
                //    let result = value.search(/^((\+|\-|)\d{2}:\d{2}(:\d{2}|))$/);
                //    return result !== -1 ? true : false;
                //}
            },
            /**
             *
             * */
            'maxValue': {
                type: String,
                default: null,
                //validator(value) {
                //    let result = value.search(/^((\+|\-|)\d{2}:\d{2}(:\d{2}|))$/);
                //    return result !== -1 ? true : false;
                //}
            },
            /**
             * смещение времени, timezone offset пользователя. Переданная дата будет отображена с учётом данной опции. 
             * Можно не указывать, тогда значение времени будет соответствовать времени события. 
             * Возможные варианты: -10:30:00, -10:30, +10:30:00, +10:30, 10:30:00, 10:30, null.
             * */
            'timezoneOffset': {
                type: String,
                default: null,
                validator(value) {
                    let result = value.search(/^((\+|-|)\d{2}:\d{2}(:\d{2}|))$/);
                    return result !== -1 ? true : false;
                }
            },
            
        },
        created() { 
        },
        mounted() {            
            this.init();
        },
        destroyed() {            
            $(this.$el).data("DateTimePicker").destroy();
        },
        data: function () {
            return {
                //dateValue: ''
                //datetimezone даты при передачи
                //dateTimeZone:
                datePicker:null
            }
        },
        watch: {
            date(value) {
                const $input = $(this.$el).find('input');
                if (value === null && $input.val() !== '') {
                    $input.val('');
                    //$(this.$el).data('daterangepicker').setStartDate('');
                }
                else {
                    //$this.data("DateTimePicker").date((value && value.format('DD.MM.YYYY HH:mm Z')) || null);
                        //$this.data('daterangepicker').setStartDate(this.startDate);
                    //this.apply($this, this.startDate, this.endDate);
                }
            },
        },
        computed: {
            date() {
                //Валидация перенесена из-за невозможности использовать this, как vm, в prop
                let momentDate = null;
                if (this.value !== null) {
                    if (this.inputFormat)
                        momentDate = moment(this.value, this.inputFormat);//'DD.MM.YYYY  новый тест'.parseZone(, this.supportedFormat, true);
                    else
                        momentDate = moment(this.value);
                    
                    if (!momentDate.isValid()) {
                        console.error('Неверный формат даты, в качестве входного параметра. Укажите свой формат в параметре "inputFormat" или приведите дату в поддерживаемый формат');//Разрешённые форматы: " + this.supportedFormat
                        return null;
                    }
                    //Если указан timeoffset в качестве атрибута
                    if (this.userTimezoneOffsetNormalized !== null) {
                        momentDate = momentDate.utcOffset(this.userTimezoneOffsetNormalized);
                    }
                }
                return momentDate;
            },
            /**
             * 
             * *///TODO:Реализовать преобразование даты
            minDate() {
                return this.minValue;
            },
            /**
             * 
             * *///TODO:Реализовать преобразование даты
            maxDate() {
                return this.maxValue;
            },
            /**
             * Формат, отображаемой в input-е, даты
             * */
            formatDisplay() {
                let _f = this.dateFormat;//'DD.MM.YYYY HH:mm';
                if (this.isAllDay) {
                    _f = this.dateFormatAllDay;//'DD.MM.YYYY';
                }
                return _f;
            },
            /**
             * Нормализованный timeOffset из исходной даты   (+00:00)
             * */
            dateTimezoneOffsetNormalized() {
                return this.date ? this.date.format('Z') : '+00:00';
            },
            /*
             * Нормализованный timeOffset (+00:00), если он был указан явно через параметр timezoneOffset
             * */
            userTimezoneOffsetNormalized() {
                let result = null;
                if (this.timezoneOffset !== null) {
                    let tzOffset = this.timezoneOffset;
                    let direction = '+';
                    if (tzOffset.charAt(0) === '-') {
                        direction = '-';
                    }
                    tzOffset = tzOffset.replace('-', '').replace('+', '');
                    let array = tzOffset.split(':');

                    result = `${direction}${array[0]}:${array[1]}`;
                }
                return result;
            }
        },
        methods: {
            init() {
                var $this = $(this.$el);
                var viewMode = $this.attr('data-dateviewmode') || "days";
                var settings = {
                    format: this.formatDisplay,
                    //defaultDate: this.date,
                    locale: this.locale,//'ru',
                    useCurrent: false,
                    viewMode: viewMode,
                    allowInputToggle: true,
                    keepOpen: false,
                    minDate: false,
                    maxDate:false,
                    widgetPositioning: {
                        horizontal: 'left',
                        vertical: 'auto'
                    },
                };
                $this.datetimepicker(settings);

                //Передаётся строка, чтобы отсечь секунды//Если будут переданы не 00 секунды, при открытии пикера будет иницировано событие change
                $this.data("DateTimePicker").date((this.date && this.date.format(this.dateFormat)) || null);//'DD.MM.YYYY HH:mm Z'
                this.valueChange(this.date);

                if (this.minDate != null)
                    $this.data("DateTimePicker").minDate(this.minDate);

                if (this.maxDate != null)
                    $this.data("DateTimePicker").maxDate(this.maxDate);

                $this.on("dp.change", (e) => {
                    this.onChange(e);
                    //this.onChangeCustom(e);
                });
                $this.on("dp.hide", this.onHide);
                $this.on("dp.show", this.onShow);
                $this.on("dp.error", this.onError);
                $this.on("dp.update", this.onUpdate);
            },
            onChange(e) {
                this.valueChange(e.date ? e.date : null);
                /**
                 * Срабатывает когда дата изменена, в том числе, когда изменяется non-date (Когда keepInvalid=true).
                 * */
                this.$emit('change', e);
            },
            valueChange(date) {
                let result = null;
                if (date !== null) {
                    let timeZone = '';
                    if (this.userTimezoneOffsetNormalized !== null) {
                        timeZone = this.userTimezoneOffsetNormalized;
                    }
                    else {
                        timeZone = this.dateTimezoneOffsetNormalized;
                    }
                    let _d = moment(date).format(`YYYY-MM-DDTHH:mm:ss${timeZone}`);
                    result = moment.parseZone(_d).format(this.outputFormat);
                    
                }
                /**
                 *  
                 * */
                this.$emit('input', result);
            },
            onHide(e) {
                /**
                 * Срабатывает когда виджет скрывается.
                 * */
                this.$emit('hide', e);
            },
            onError(e) {
                /**
                 * Срабатывает когда выбранная дата не проходит проверку валидации.
                 * */
                this.$emit('error', e);
            },
            onShow(e) {
                /**
                 * Срабатывает когда виджет отображается.
                 * */
                this.$emit('show', e);
            },
            onUpdate(e) {
                /**
                 * Fired (in most cases) when the viewDate changes. E.g. Next and Previous buttons, selecting a year.
                 * */
                this.$emit('update', e);
            },
        },
        //components: {
        //       
        //}
    }
</script>
<style>
</style>
