import userService from '../../../services/user.service';
//import axios from 'axios';
export default {
    
    async GET_PROFILE({ commit }) {
        const profile = await userService.getProfile();
        commit('UpdateProfile', profile);
    },

};
