import elapsedTimeString from "../filters/elapsedTimeString.js";
import getPluralizedString from "../filters/getPluralizedString.js";
import getNumPluralizedString from "../filters/getNumPluralizedString.js";

/**
 * You can register global filters here and use them as a plugin in your main Vue instance
 */

const GlobalFilters = {
  install(Vue) {
        Vue.filter('elapsedTimeString', elapsedTimeString);
        Vue.filter('getPluralizedString', getPluralizedString);
        Vue.filter('getNumPluralizedString', getNumPluralizedString);
  }
};

export default GlobalFilters;
