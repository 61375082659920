import mutations from './mutations.js';
import actions from './actions.js';
import { state, getters } from './state.js';

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};
