const ValueType = {
    "DICTIONARY": 0,
    "BOOL": 1,
    "INT": 2,
    "DOUBLE": 3,
    "STRING": 4,
    "DATE": 5,
    "DATETIME": 6,
    "TEXT": 7
};
Object.freeze(ValueType);

export {
    ValueType
}
