import Vue from "vue";
import Vuex from "vuex";

import ticketModule from './modules/tickets';
import profileModule from './modules/profile';
import organizationModule from './modules/organization';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        ticketModule,
        profileModule,
        organizationModule
    }
});
