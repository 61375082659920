import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Main from "./views/Main.vue";
import Components from "./views/Components.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import ChangePassword from "./views/ChangePassword.vue";
import Logout from "./views/Logout.vue";
import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";
import TicketCreate from "./views/NewTicket.vue";
import TicketDetails from "./views/Ticket.vue";
import Tickets from "./views/Tickets.vue";

import NotFound from "./views/NotFound.vue";
import ViewError from "./views/Error.vue";

import TokenService from './services/token.service';
import ApiService from './services/api.service';

Vue.use(Router);

const routes = [
        {
            path: '*',
            name:'notFound',
            components: {
                header: AppHeader,
                default: NotFound
            }
        },
        {
            path: '/error',
            name: 'error',
            components: {
                header: AppHeader,
                default: ViewError
            }
        },
        {
            path: "/components",
            name: "components",
            components: {
                header: AppHeader,
                default: Components,
            }
        },
        {
            path: "/",
            name: "main",
            components: {
                header: AppHeader,
                default: Main,
            },
            redirect: "/tickets"
        },
        {
            path: "/tickets",
            name: "tickets",
            components: {
                header: AppHeader,
                default: Tickets,
            }
        },
        {
            path: "/tickets/create",
            name: "ticketCreate",
            components: {
                header: AppHeader,
                default: TicketCreate,
            }
        },
        {
            path: "/tickets/:id",
            name: "ticketDetails",
            components: {
                header: AppHeader,
                default: TicketDetails,
            }
        },
    {
        path: "/(@):organization",
        name: "organization",
        //redirect: "/"
    },

    {
        path: "/landing",
        name: "landing",
        components: {
            header: AppHeader,
            default: Landing,
        }
    },
    {
        path: "/login",
        name: "login",
        components: {
            default: Login,
        },
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    {
        path: "/change_password",
        name: "change_password",
        components: {
            default: ChangePassword,
        },
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    {
        path: "/register",
        name: "register",
        components: {
            header: AppHeader,
            default: Register,
        },
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    {
        path: "/logout",
        name: "logout",
        components: {
            default: Logout,
        },
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    {
        path: "/profile",
        name: "profile",
        components: {
            header: AppHeader,
            default: Profile,
        }
    }
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: "active",

    routes: routes,

    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});


router.beforeEach((to, from, next) => {
    var orgId = to.params.organization;
    if (orgId) {
        ApiService.setHeader('OrganizationId', orgId);
        return next({
            name: 'main'
        })
    }

    const isPublic = to.matched.some(record => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
    const loggedIn = !!TokenService.getToken();

    if (!isPublic && !loggedIn) {
        return next({
            name: 'login',
            query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
        });
    }

    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
            return next({
                name: 'main'
            })
    }

    next();
})


export default router;