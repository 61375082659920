<template>
    <section class="login section section-shaped section-lg my-0">
        <div class="shape shape-style-1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <img src="../assets/img/logo_in_login.svg" class="mb-5" />
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-4 py-lg-4"
                          class="border-0">

                        <!-- sign in -->
                        <template v-if="mode=='signin'">
                            <h5 class="color-black text-center text-muted mb-4">
                                Войти
                            </h5>
                            <form role="form">
                                <div class="form-group">
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    <base-input v-model="email"
                                                v-on:keyup.enter="login"
                                                alternative
                                                class="mb-3"
                                                type="email"
                                                name="email"
                                                required autocomplete="email"
                                                placeholder="Email">
                                    </base-input>
                                    <span class="small text-danger" v-if="$v.email.$error">
                                        Укажите адрес электронной почты
                                    </span>
                                </div>
                                <div class="form-group">
                                    <i class="fa fa-lock" aria-hidden="true"></i>
                                    <base-input v-model="password"
                                                v-on:keyup.enter="login"
                                                class="mb-2"
                                                alternative
                                                type="password"
                                                name="password"
                                                required autocomplete="password"
                                                placeholder="Пароль">
                                    </base-input>
                                    <p class="forgot-password small text-right mr-2 mb-4">
                                        <a href="#" @click="setMode('forgot_password')">Напомнить пароль</a>
                                    </p>
                                    <span class="small text-danger" v-if="$v.password.$error">
                                        Укажите пароль от 6 символов
                                    </span>
                                </div>
                                <div class="text-center">
                                    <base-button @click="login" type="danger" class="mb-4">Войти</base-button>
                                    <p class="typo__p text-danger" v-if="submitStatus === 'EMPTY_ORG'">Не указана организация. Пожалуйста, перейдите по ссылке, предоставленной организацией</p>
                                </div>
                            </form>
                        </template>

                        <!-- sign up -->
                        <template v-if="mode=='signup'">
                            <h5 class="color-black text-center text-muted mb-4">
                                Зарегистрироваться
                            </h5>
                            <form role="form" @submit.prevent="submit">
                                <div class="form-group">
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    <base-input v-model="email"
                                                v-on:keyup.enter="register"
                                                alternative
                                                class="mb-3" :class="{ 'form-group--error': $v.email.$error }"
                                                type="email"
                                                required autocomplete="email"
                                                placeholder="Email">
                                    </base-input>
                                    <span class="small text-danger" v-if="$v.email.$error">
                                        Укажите адрес электронной почты
                                    </span>
                                </div>
                                <div class="form-group">
                                    <i class="fa fa-lock" aria-hidden="true"></i>
                                    <base-input v-model="password"
                                                v-on:keyup.enter="register"
                                                alternative
                                                type="password"
                                                :class="{ 'form-group--error': $v.password.$error }"
                                                name="password"
                                                required autocomplete="password"
                                                placeholder="Пароль">
                                    </base-input>
                                    <span class="small text-danger" v-if="$v.password.$error">
                                        Укажите пароль от 6 символов
                                    </span>
                                </div>
                                <div class="form-group">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    <base-input v-model="firstName"
                                                v-on:keyup.enter="register"
                                                alternative
                                                name="fname"
                                                :class="{ 'form-group--error': $v.firstName.$error }"
                                                required autocomplete="name"
                                                placeholder="Имя">
                                    </base-input>
                                    <span class="small text-danger" v-if="$v.firstName.$error">
                                        Укажите имя кириллицей
                                    </span>
                                </div>
                                <div class="form-group">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    <base-input v-model="lastName"
                                                v-on:keyup.enter="register"
                                                alternative
                                                name="lname"
                                                :class="{ 'form-group--error': $v.lastName.$error }"
                                                required autocomplete="family-name"
                                                placeholder="Фамилия">
                                    </base-input>
                                    <span class="small text-danger" v-if="$v.lastName.$error">
                                        Укажите фамилию кириллицей
                                    </span>
                                </div>
                                <div class="form-group DN">
                                    <i class="fa fa-globe" aria-hidden="true"></i>
                                    <base-input v-model="location"
                                                v-on:keyup.enter="register"
                                                :class="{ 'form-group--error': $v.location.$error }"
                                                alternative
                                                placeholder="Часовой пояс">
                                    </base-input>
                                </div>
                                <div class="text-center">
                                    <base-button @click="register" :disabled="submitStatus === 'PENDING'" type="danger" class="mb-4">Регистрация</base-button>
                                    <p class="typo__p text-danger" v-if="submitStatus === 'EMPTY_ORG'">Не указана организация. Пожалуйста, перейдите по ссылке, предоставленной организацией</p>
                                    <p class="typo__p text-danger" v-if="submitStatus === 'INVALID'">Пожалуйста, заполните форму корректно</p>
                                </div>
                            </form>
                        </template>

                        <!-- lamoda -->
                        <template v-if="mode=='lamoda'">
                            <h5 class="color-black text-center text-muted mb-4">
                                Войти под организацией <span style=" transform: rotate(5deg); display: inline-block; "> La </span>moda
                            </h5>
                            <div class="text-center">
                                <base-button @click="loginLamoda" type="danger" class="mb-4">Войти</base-button>
                            </div>                            
                        </template>

                        <template v-if="mode=='forgot_password'">
                            <h5 class="color-black text-center text-muted mb-4">
                                Напомнить пароль
                            </h5>
                            <form role="form" @submit.prevent="submit">
                                <div class="form-group">
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    <base-input v-model="email"
                                                v-on:keyup.enter="forgotPassword"
                                                alternative
                                                class="mb-3" :class="{ 'form-group--error': $v.email.$error }"
                                                type="email"
                                                required autocomplete="email"
                                                placeholder="Email">
                                    </base-input>
                                    <span class="small text-danger" v-if="$v.email.$error">
                                        Укажите адрес электронной почты
                                    </span>
                                </div>
                                <div class="text-center">
                                    <base-button @click="forgotPassword" :disabled="submitStatus === 'PENDING' || submitStatus === 'RECOVER_STARTED'" type="danger" class="mb-4">Напомнить</base-button>
                                    <p class="typo__p text-danger" v-if="submitStatus === 'RECOVER_ERR'">Возникла ошибка при вызове процедуры сброса пароля. Обратитесь в службу поддержки пользователей.</p>
                                    <p class="typo__p text-result" v-if="submitStatus === 'RECOVER_STARTED'">На указанный e-mail была выслана ссылка для восстановления пароля.</p>
                                </div>
                            </form>
                        </template>

                        <!-- external sign on -->
                        <template v-if="mode!='lamoda'">
                            <div class="form-footer text-muted text-center mb-3">
                                <small>или</small>
                            </div>
                            <div>
                                <ul class="social-networks-links">
                                    <li class="DN">
                                        <a @click="fbLogin">
                                            <i class="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="DN">
                                        <a>
                                            <i class="fa fa-vk" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="DN">
                                        <a>
                                            <i class="fa fa-odnoklassniki" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="DN">
                                        <a>
                                            <i class="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="google-signin-button">
                                            <i class="fa fa-google" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <base-button @click="kcLogin" type="primary" class="mb-4" icon="fa fa-code" id="keycloak-signin-button">
                                            RDM
                                        </base-button>
                                        <!-- <a class="btn btn-neutral color-black" id="keycloak-signin-button">
                        Keycloak
                        <i class="fa fa-odnoklassniki" aria-hidden="false"></i>
                    </a> -->
                                    </li>
                                </ul>
                                <!--<div  class="g-signin2"></div>--><!--data-onsuccess="onGoogleSignIn"-->
                            </div>
                            <div class="text-muted text-center mb-3 mt-3">
                                <small v-if="mode=='signin'">Впервые здесь? <a href="#" @click="setMode('signup')">Зарегистрируйтесь</a></small>
                                <small v-if="mode=='signup'">Уже есть аккаунт? <a href="#" @click="setMode('signin')">Войдите</a></small>
                                <small v-if="mode=='forgot_password'">Вспомнили пароль? <a href="#" @click="setMode('signin')">Войти</a></small>
                            </div>
                        </template>
                    </card>
                    <div class="login-footer">

                        <div class="mr-3 text-left">
                            <a href="https://id2.action-media.ru/l/politika_pd?id=11" target="_blank" class="text-light">
                                <span class="fs-11">Политика обработки персональных данных</span>
                            </a>
                        </div>
                        <div class="mr-3">
                            <a href="https://xsud.ru/help2/about-2" target="_blank" class="text-light">
                                <span class="fs-11">Подробнее о системе</span>
                            </a>
                        </div>
                        <div class="mr-3 text-right">
                            <a href="https://xsud.ru/help2" target="_blank" class="text-light">
                                <span class="fs-11">Справка</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

    .login .container {
        text-align: center;
    }

    .login .color-black {
        color: #222222 !important;
    }

    .login .bg-secondary {
        background-color: #fff !important;
    }

    .form-group i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
    }

        .form-group i.fa-envelope-o {
            margin-left: -5px;
        }

    .form-group .input-group-alternative {
        display: inline-block;
        width: 270px;
    }

    .form-group.input-group-alternative {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #D2D2D2;
        width: calc(100% - 30px);
    }

    .form-group.form-group--error {
        border-color: firebrick;
    }

    .form-group.input-group:focus { /*???*/
        border-bottom: 1px solid #222222;
    }

    .form-group.input-group-alternative input {
        padding-left: 0px;
    }


    .login .form-group {
        text-align: left;
    }

        .login .form-group .text-danger {
            margin-left: 29px;
        }

    .social-networks-links li {
        display: inline-block;
        /*margin-right: 10px;*/
    }

        .social-networks-links li a {
            display: block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            color: #fff !important;
            background-color: rgb(113, 127, 139);
            cursor: pointer;
        }

            .social-networks-links li a:hover {
                opacity: 0.8;
            }

            .social-networks-links li a i {
                font-size: 12px;
                padding-top: 7px;
            }

    .form-footer {
        position: relative;
    }

        .form-footer::before,
        .form-footer::after {
            content: "";
            display: block;
            height: 1px;
            width: calc(50% - 25px);
            background: #D2D2D2;
            margin: 16px 0 0 20px;
            position: absolute;
            top: -3px;
        }

        .form-footer::before {
            left: -16px;
        }

        .form-footer::after {
            right: 3px;
        }

    .social-networks-links {
        margin: 10px 0px 0px 0px;
        padding: 0;
        overflow: hidden;
        text-align: center;
        padding-top: 3px
    }

    .login-footer {
        /*width: 480px;*/
        font-size: 12px;
        color: #828282;
        margin-top: 134px;
        margin-right: -5px !important;
        margin-right: 0px;
    }

        .login-footer div {
            padding: 0px;
            display: inline-block;
        }

    .login #keycloak-signin-button {
        height: 36px;
        line-height: 14px;
        margin-left: 20px;
    }

    .login #google-signin-button .abcRioButtonLightBlue {
        width: 94px !important;
    }

        .login #google-signin-button .abcRioButtonLightBlue .abcRioButtonIcon {
            padding-right: 0px !important;
        }

        .login #google-signin-button .abcRioButtonLightBlue .abcRioButtonContents {
            font-size: 12px !important;
            line-height: 34px;
            margin: 0px;
            text-transform: uppercase;
        }

            .login #google-signin-button .abcRioButtonLightBlue .abcRioButtonContents span {
                vertical-align: middle;
            }

    .login a:not([href]):not([tabindex]) {
        width: auto;
        height: initial;
    }

    .login .forgot-password a {
        color: #8898aa;
    }

        .login .forgot-password a:hover {
            color: #233dd2;
        }

    .login .text-result {
        color: #065095;
    }
    @media (min-width: 1200px) {

        .login .container {
            max-width: 1040px;
        }
    }

    @media (max-width: 991px) {
        .login {
            padding-top: 0px !important;
        }
    }
</style>

<!--<script src="https://apis.google.com/js/api:client.js"></script>-->
<script>


    import { required, /*maxLength,*/ minLength, email } from 'vuelidate/lib/validators';
    import { UserService, UserLamoda } from '../services/user.service';
    import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { async } from 'q';

    var vm = {
        components: {

        },
        data: () => ({
            mode: 'signin',
            email: null,
            password: null,

            firstName: null,
            lastName: null,
            location: null,

            keep: false,
            submitStatus: null
        }),
        watch: {
            mode() {
                this.$v.$reset();
            }
        },
        async mounted() {

            if (window.location.search.includes('redirect=%2Flogin%2Flamoda')) {
                this.mode = 'lamoda';

                if (window.location.search.includes('userId')) {

                    var user = decodeURIComponent(decodeURIComponent(location.search.split('userId%3D')[1]));
                    var organizationId = await UserLamoda.SignInPortal(user);
                    if (organizationId) {
                        this.$route.query.organizationId = organizationId;
                        this.$route.query.redirect = "/tickets";
                        this.goHome();
                    }
                }
            }

            // let keycloak = await this.kcInit();
            
            // console.log('keycloak provider init started..');
            // const authState = await keycloak.init({checkLoginIframe: false});
            // console.log('keycloak auth:' + authState);
            // if (authState) {
            //     console.log(keycloak.tokenParsed);
            // }
            // else {
            //     //keycloak.login
                
            // }
            let waitingGapiLoading = setInterval(() => {
                //gapi.load('auth2', () => {
                if (typeof gapi !== 'undefined') {
                    gapi.signin2.render('google-signin-button', {
                        longtitle: false,
                        //'theme': 'material',
                        //width: 'iconOnly',
                        //heigth:'20px',
                        onsuccess: this.onSignIn
                    });
                    clearInterval(waitingGapiLoading);
                }
            }, 100);

            //gapi.auth2.init().then(() => {
            //    if (gapi.auth2) {
            //        var auth2 = gapi.auth2.getAuthInstance();
            //        if (auth2)
            //            auth2.signOut();
            //    }
            //});
            //});
            //gapi.load('auth2', function () {
            //    debugger;
            //    // Retrieve the singleton for the GoogleAuth library and set up the client.
            //    auth2 = gapi.auth2.init({
            //        client_id: '723040825354-m4uu04iqsus448opusjftj62icbrv5pk.apps.googleusercontent.com',
            //        cookiepolicy: 'single_host_origin',
            //        // Request scopes in addition to 'profile' and 'email'
            //        //scope: 'additional_scope'
            //    });

            //    auth2.attachClickHandler(document.getElementById('google-signin-button-1'), {},
            //        function (googleUser) {
            //            this.onSignIn(googleUser);
            //        }, function (error) {
            //            //alert(JSON.stringify(error, undefined, 2));
            //        });
            //});


        },
        methods: {
            setMode: function (mode) {
                this.mode = mode;
            },

            goHome: async function () {

                try {

                    await this.saveLinkOrganization();

                var redirect = this.$route.query.redirect;
                    this.$router.push(redirect || '/');

                } catch (error) {
                    window.console.log(error);
                    this.$notify({
                        title: 'Ошибка',
                        text: 'Использование этого способа авторизации для выбранной организации недопустимо',
                        type: 'error'
                    });
                }
            },
            saveLinkOrganization: async function () {
                var organizationId = this.$route.query.organizationId;
                if (organizationId)
                    await UserService.saveOrganizationLink(organizationId);
            },

            forgotPassword: async function() {
                this.$v.$touch();
                if (!(this.$v.email.$invalid)) {
                    this.submitStatus = 'PENDING';
                    try {
                        const resetRes = await UserService.passwordReset(this.email);
                        if (resetRes) {
                            this.submitStatus = 'RECOVER_STARTED';
                        }
                        else {
                            this.submitStatus = 'RECOVER_ERR';
                        }
                    } catch (error) {
                        this.submitStatus = 'RECOVER_ERR';
                        window.console.log(error);
                    }
                }
            },

            register: async function () {
                this.$v.$touch()
                if (!this.$route.query.organizationId) {
                    this.submitStatus = 'EMPTY_ORG';
                } else if (this.$v.$invalid) {
                    this.submitStatus = 'INVALID';
                } else {
                    // do your submit logic here
                    this.submitStatus = 'PENDING';
                    try {
                        var result = await UserService.register(this.email, this.password, this.firstName, this.lastName, this.location, new URL(window.location).searchParams.get('organizationId'));
                        //this.goHome();

                        if (result?.error) {
                            this.$notify({
                                title: 'Ошибка',
                                text: result.message,
                                type: 'error'
                            });
                        } else {
                            this.$notify({
                                title: 'Регистрация прошла успешно',
                                type: 'success'
                            });
                            await this.login();
                        }
                    } catch (error) {
                        this.submitStatus = 'ERROR';
                        window.console.log(error);
                        this.$notify({
                            title: 'Ошибка',
                            text: 'Регистрация не прошла.',
                            type: 'error'
                        });
                    }
                }
            },

            login: async function () {
                this.$v.$touch();
                if (!(this.$v.email.$invalid || this.$v.password.$invalid)) {
                    try {
                        await UserService.login(this.email, this.password);
                        this.goHome();
                    } catch (error) {
                        this.$notify({
                            title: 'Ошибка',
                            text: 'Неверный логин или пароль',
                            type: 'error'
                        });
                        window.console.log(error);
                    }
                }
            },
            loginLamoda: async function () {
                UserService.LamodaAuthentication(); 
            },
            fbLogin: async function () {
                await UserService.fbLogin();
            },
            // kcInit: async function() {
            //     let kcConfig = {
            //         url: "http://localhost:8080/",
            //         realm: "actiontest",	
            //         clientId: "xsud"
            //     };
            //     let res = new Keycloak(kcConfig);
            //     res.init({checkLoginIframe: false}).success(function(auth){
            //         console.log("wtf:"+auth);
            //     });
            //     return res;
            // },
            kcLogin: async function() {
                let initOptions = await UserService.getKeycloakConfigs();
                if (!initOptions || !initOptions["RDM"]) {
                    console.error("No configurations found");
                }
                let keycloak = Keycloak(initOptions["RDM"]);

                keycloak.init({ onLoad: initOptions.onLoad }).then(async (auth) => {
                if (!auth) {
                    window.location.reload();
                } else {
                    console.info("success");
                    console.info(keycloak.token);
                    console.info(keycloak.tokenParsed);
                    await UserService.keycloakLogin(keycloak.token);
                }


                //Token Refresh
                setInterval(() => {
                    keycloak.updateToken(70).then((refreshed) => {
                    if (refreshed) {
                        console.info('Token refreshed' + refreshed);
                    } else {
                        console.warn('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                    }
                    }).catch(() => {
                        console.error('Failed to refresh token');
                    });
                }, 6000)

                }).catch(() => {
                    console.error("Authenticated Failed");
                });

                // const kc = new Keycloak(kcConfig);
                // var auth = kc.init({
                //     checkLoginIframe: true,
                //     enableLogging: true,
                //     redirectUri: "http://localhost:8081/"
                // }).then(function(auth){
                //     console.info(auth);
                // });
                // var options = {
                //     scope: "openid",
                //     redirectUri: "http://localhost:8081/"
                
                // };
                
                // console.info(kc.token);
                // console.info(kc.tokenParsed);
                //var keycloak = await this.kcInit();
                //keycloak.login(options);
            },
            //gLogin: async function () {
            //    await UserService.gLogin();
            //},
            onSignIn: async function (googleUser) {
                const accessToken = googleUser.getAuthResponse().id_token;
                const hasUser = await UserService.checkHasGoogleUser(accessToken);
                if (!hasUser && !this.$route.query.organizationId) {
                    this.submitStatus = 'EMPTY_ORG';
                } else {
                    await UserService.googleLogin(googleUser);
                    this.goHome();
                }
            }

        },
        //created: {

        //},

        // Модель для валидации, которую Vuelidate превратит в computed-поле $v
        validations: {
            // Название поля должно совпадать с полем в data
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(6)
            },
            firstName: {
                required,
                //maxLength: maxLength(10),
                alpha: val => /^[а-яё]*$/i.test(val),
            },
            lastName: {
                required,
                //maxLength: maxLength(10),
                alpha: val => /^[а-яё]*$/i.test(val),
            },
            location: {
                //required,
                //maxLength: maxLength(10),
                //alpha: val => /^[а-яё]*$/i.test(val),
            },
        }

    };

    //window.onGoogleSignIn = async function (googleUser) {
    //    await UserService.googleLogin(googleUser);
    //    debugger;
    //    vm.methods.goHome();
    //}

    export default vm;
</script>

