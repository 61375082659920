<template function>
    <div>
        <div class="error-container">
            <div>
                <div class="error-img text-danger" style="">500</div>
                <p class="text-center"><span class="">Произошла непредвиденная ошибка</span></p>
                <div>
                    <a class="btn btn-neutral" @click="refresh">Обновить</a>
                    <router-link to="/" class="btn btn-neutral pull-right">На главную</router-link>

                </div>

            </div>
        </div>
        
    </div>
</template>
<script>
    export default {
        created() {
            
        },
        methods: {
            refresh() {
                window.location.reload();
            }
        },
        components: {
            
        }
    };
</script>
<style>
    .error-container{
        display:flex;
        justify-content:center;
        align-items:center;
        height:100%;
            padding: 10vh 0;
    }

    .error-img{
        font-size:200px;line-height: 1em;
    }
</style>
