<template>
    <div class="ticket-page">
        <section v-if="ticket" class="">
            <div class="container ">
                <div class="btn-container">
                    <a @click="$router.go(-1)" class="btn btn-neutral color-black ">
                        Назад
                    </a>
                    <a class="close-ticket" v-if="ticket.status !== 2">
                        <base-button class="my-0 btn btn-neutral"
                                     @click="closedTicket">
                            Закрыть обращение
                        </base-button>
                    </a>
                </div>
                <card shadow class="ticket-list" no-body>
                    <div class="ticket-container">
                        <div class="row plate py-3">
                            <div class="col-lg-8 ticket-content">
                                <div>
                                    <span v-if="ticket.title" class="ticket-title">{{ticket.title}}</span>
                                    <span v-if="ticket.number" class="number color-gray pl-1 pr-2">#{{ticket.number}}</span>
                                    <span class="state" :class="statusClass">{{ticket.status | statusText}}</span>
                                </div>
                                <div class="ticket-description">
                                    <template v-if="ticket.type">
                                        <span class="color-gray ticket-text">
                                            {{ticket.type}}
                                        </span>
                                    </template>
                                    <template v-if="ticket.text">
                                        <span class="ticket-text" :class="{'show-all': show}">
                                            {{ticket.text}}
                                        </span>
                                    </template>
                                    <div v-if="extendedAttributes && extendedAttributes.length > 0"
                                         class="mt-4 mb-4">
                                        <a @click="toggleExtendedAttributeDisplayable">
                                            <h6 class="font-weight-bold">
                                                <span v-show="extendedAttributeDisplayable" class="fa fa-caret-up"></span>
                                                <span v-show="!extendedAttributeDisplayable" class="fa fa-caret-down"></span>
                                                Дополнительные атрибуты
                                            </h6>
                                        </a>
                                        <div v-show="extendedAttributeDisplayable">
                                            <div v-for="attr of extendedAttributes"
                                                 :key="attr.name">
                                                <vue-extended-attribute-display :attribute="attr"
                                                                                :attribute-schema="attr.schema">
                                                </vue-extended-attribute-display>
                                            </div>
                                        </div>
                                    </div>
                                    <small v-if="ticket.text && ticket.text.length > 20" class="btn-text show-text">
                                        <span :class="{'DN': show}"
                                              class="color-gray"
                                              @click="showText()">
                                            Показать весь текст
                                            <i class="fa fa-caret-down pl-2" aria-hidden="true"></i>
                                        </span>
                                        <span :class="{'DN': !show}"
                                              @click="showText()">
                                            Скрыть текст
                                            <i class="fa fa-caret-up pl-2" aria-hidden="true"></i>
                                        </span>
                                    </small>
                                </div>
                                <div>
                                    <base-textarea v-model="commentText"
                                                   placeholder="Введите текст комментария"
                                                   @keyup.ctrl.enter="addComment"
                                                   ref="comment"
                                                   v-show="addCommentInProcess">
                                        <template slot="label">
                                            <label>
                                                Текст комментария
                                            </label>
                                            <a class=" text-muted" style="float:right;cursor:pointer"
                                               @click="cancelComment">Свернуть</a>
                                        </template>
                                    </base-textarea>
                                    <div>
                                        <div class="text-left add-comment">
                                            <base-button v-show="!addCommentInProcess"
                                                         type="danger" class="my-0"
                                                         @click="toAddComment">
                                                Добавить комментарий
                                            </base-button>
                                            <base-button v-show="addCommentInProcess"
                                                         type="danger" class="my-0"
                                                         @click="addComment">
                                                Отправить<span class="ml-1 ni ni-send "></span>
                                            </base-button>
                                        </div>

                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div>
                                        <span class="mr-2" v-if="commentsTotalCount != 0">
                                            {{commentsTotalCount  | getNumPluralizedString('комментарий', 'комментария', 'комментариев')}}
                                        </span>
                                    </div>
                                    <div class="comment-list">
                                        <transition-group name="slider">
                                            <div v-for="comment in comments" class="mt-3 comment"
                                                 :key="comment.id">
                                                <div class="">
                                                    <img src="../assets/img/male.png" class="mr-2" />
                                                </div>
                                                <div class="comment-content">
                                                    <div class="mb-2 mt-1 fs-12 ">
                                                        <span class="mr-2">{{comment.author||'Не назначен'}}</span>
                                                        <span class="color-gray">{{comment.createdDate | elapsedTimeString(60)}}</span>
                                                    </div>
                                                    <span class="comment-text">{{comment.text}}</span>
                                                </div>
                                            </div>
                                        </transition-group>
                                    </div>
                                    <base-pagination v-if="commentsTotalCount > commentsTake"
                                                     align="center"
                                                     :per-page="commentsTake"
                                                     :total="commentsTotalCount"
                                                     :value="commentsPage"
                                                     @input="changeCommentPage">

                                    </base-pagination>
                                </div>
                            </div>
                            <div class="col-lg-4 ticket-change">
                                <div><span class="color-gray">Создан: </span>{{ticket.createdDate | elapsedTimeString(60)}}</div>
                                <div><span class="color-gray" v-if="ticket.closedDate && ticket.status == 2">Закрыт: </span>{{ticket.closedDate | elapsedTimeString(60)}}</div>
                                <div><span class="color-gray">Ответственный: </span>{{performer}}</div>
                                <div class="icon-container">
                                    <span class="empty btn-text">{{attachments.length}}<i class="fa fa-file-o pl-2" aria-hidden="true"></i></span>
                                    <span class="pl-4 btn-text">{{commentsTotalCount}}<i class="fa fa-comment-o pl-2" aria-hidden="true"></i></span>
                                </div>
                                <div>
                                    <!--для детализации-->
                                    <div v-if="attachments.length > 0">
                                        <span>Прикреплённые файлы:</span>

                                        <ul class="file-list p-0 mt-2">
                                            <li v-for="file in attachments" class="my-2 file" :key="file.id">
                                                <span @click="downloadFile(file.id)">
                                                    {{file.originalFileName}}
                                                    <span class="btn-deleteFile" @click="deleteFile(file.id)" title="Удалить">x</span>
                                                </span>

                                            </li>
                                        </ul>

                                    </div>
                                    <div>
                                        <vue-dropzone id="drop" :options="dropOptions"
                                                      :use-custom-slot="true"
                                                      :duplicate-check="true"
                                                      ref="dropzone"
                                                      @vdropzone-upload-progress="uploadProgress"
                                                      @vdropzone-file-added="addedFile"
                                                      @vdropzone-drag-start="dragStart"
                                                      @vdropzone-success="onFileSuccess"
                                                      @vdropzone-complete="onFileComplete"
                                                      @vdropzone-duplicate-file="duplicateFile"
                                                      @vdropzone-canceled="onFileCanceled"
                                                      @vdropzone-error="onFileError"
                                                      @vdropzone-sending="sendingFile"
                                                      @vdropzone-queue-complete="queueCompleteFile">
                                            <form role="form">
                                                <div id="ticket-file-container" class="file-container">
                                                </div>
                                                <span >
                                                    <span class="fa fa-paperclip text-danger"></span> Переместите файл сюда или
                                                    <a id="file-btn-upload" class="file-btn-upload text-muted">загрузите с компьютера</a>
                                                </span>

                                                <base-button class="send-files" v-show="addFileInProcess"
                                                             type="danger" 
                                                             @click="sendFiles">
                                                    Отправить<span class="ml-1 ni ni-send "></span>
                                                </base-button>
                                            </form>
                                        </vue-dropzone>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import ticketService from '../services/ticket.service';
    import cloneDeep from 'lodash/cloneDeep';
    import { TicketStatus } from '../models/ticket.js';
    import vueCommentsBlock from './components/CommentsBlock.vue';
    import VueExtendedAttributeDisplay from '@/components/VueExtendedAttributeDisplay.vue';
    import ApiService from '../services/api.service';
    import VueDropzone from 'vue2-dropzone';
    import VueExtendedAttributeInput from '@/components/VueExtendedAttributeInput';


    const getPreviewTemplate = () => `<div class="file-item ">
                                                <div class="file-extension text-muted" data-dz-extension ></div>
                                                <div class="file-description">
                                                    <span class="file-name" data-dz-name></span>
                                                    <span class="file-delete-btn"></span>
                                                    <span class="file-progress-percents">0%</span>
                                                    <div class="file-progress-bar-wrapper">
                                                        <div class="file-progress-bar" data-dz-uploadprogress></div>
                                                    </div>
                                                    <div class="file-error-wrapper">

                                                    </div>
                                                </div>`;

    var checkFileExtension = function (fileExtension) {
        if (fileExtension) {
            var alowedFileExtensions = [
                'TIFF', 'TIF', 'JPEG', 'JPG',
                'JPE', 'BMP', 'PNG', 'PIC',
                'GIF', 'PSD', 'RAW', 'TGA',
                'WDP', 'HDP', 'CPT', 'CDR',
                'CGM', 'WMF', 'EMF', 'SVG',
                'AI', 'MSG',
                'DOC', 'DOCX', 'DOCM', 'DOT',
                'DOTM', 'DOTX', 'XLS', 'XLSX',
                'XLR', 'XLSM', 'XLT', 'XLTM',
                'XTLX', 'PPTX', 'PPT', 'POT',
                'POTM', 'POTX', 'PPS', 'PPSM',
                'PPSX', 'PPTM', 'SLDM', 'PDF',
                'DJVU', 'DJV', 'ODF', 'RTF',
                'TXT', 'CSV', 'KEY', 'PAGES',
                'EPUB', 'FB2', 'IBOOKS', 'GPX',
                'KML', 'MDF', 'MOBI', 'PUB,',
                'MSO', 'ODS', 'ODT', 'SNB',
                'WPD', 'WPS', 'XPS',
                'AIFF', 'AIF', 'AU', 'SND',
                'MID', 'MIDI', 'MP3', 'MPGA',
                'M4A', 'MP4', 'MPG4', 'WAV',
                'WAVE', 'BWF', 'WMA', 'MP2',
                'ASF', 'AVI', 'M4V', 'MOV',
                'MPG', 'MPEG', 'SWF', 'WMV',
                'VFW', 'MPE', 'M75', 'M15',
                'M2V', 'TS', 'QT', 'DV',
                'DIF', '3GP', 'MKV', 'WEBM',
                'RAR', 'ZIP', '7Z'
            ];
            for (var i = 0; i < alowedFileExtensions.length; i++)
                if (fileExtension.toUpperCase().indexOf(alowedFileExtensions[i]) > -1)
                    return true;
        }
    };

    export default {
        data: function () {
            return {

                addCommentInProcess: false,
                addFileInProcess: false,
                commentText: '',
                show: false,
                ticket: null,

                loadingComments: false,

                comments: [],
                commentsTake: 10,
                commentsPage: 0,
                commentsTotalCount: 0,

                attachments: [],
                attachmentsTotalCount: 11,

                extendedAttributeDisplayable: true,
                dropOptions: {
                    url: `${ApiService.getBaseUrl()}/tickets/{{ticketId}}/files`,
                    headers: {
                        'Authorization': ApiService.getHeader('Authorization')
                    },
                    parallelUploads: 10,
                    maxFiles: 10,
                    maxFilesize: 50,
                    autoProcessQueue: false,
                    dictDefaultMessage: 'Перенесите файлы сюда или загрузите с компьютера',
                    dictResponseError: 'Не удалось загрузить файл',
                    dictFallbackMessage: "Ваш браузер не поддерживает перетаскивание файлов",
                    dictFallbackText: "Пожалуйста, воспользуйтесь формой загрузки",
                    dictFileTooBig: "Размер файла ({{filesize}}MiB) превышает максимально допустимый: {{maxFilesize}}MiB",
                    dictInvalidFileType: "Вы не можете загрузить файлы данного формата",
                    previewTemplate: getPreviewTemplate(),
                    previewsContainer: '.file-container',
                    clickable: '.file-btn-upload'
                },
            }
        },
        computed: {
            ...mapState('ticketModule', {
                'tickets': state => state.tickets
            }),
            ...mapGetters('organizationModule', {
                'ticketAttributeSchema': 'ticketAttributeSchema',
            }),
            schemaAttributes() {
                return this.ticketAttributeSchema && this.ticketAttributeSchema.attributes;
            },
            statusClass() {
                let styleClass = '';
                switch (this.ticket.status) {
                    case TicketStatus.New:
                        styleClass = 'new-state';
                        break;
                    case TicketStatus.InWorking:
                        styleClass = 'active-state';
                        break;
                    case TicketStatus.Closed:
                        styleClass = 'close-state';
                        break;
                    default:
                }
                return styleClass;
            },
            performer: function () {
                return this.ticket.performer ? this.ticket.performer.caption : 'Н/Д';
            },
            extendedAttributes() {
                let result = [];
                if (this.schemaAttributes) {
                    result = this.schemaAttributes.map(attr => {
                        const ticketAttribute = this.ticket
                            && this.ticket.extendedAttributes
                            && this.ticket.extendedAttributes.find(x => x.name == attr.name);
                        return ({
                            name: attr.name,
                            caption: attr.caption,
                            valueType: attr.type,
                            value: ticketAttribute && ticketAttribute.value,
                            description: ticketAttribute && ticketAttribute.description,
                            schema: attr
                        })
                    });
                }
                return result;
            }
        },
        async created() {
            let id = this.$route.params.id;
            if (id) {
                await this.GET_TICKET(id);
                let ticket = this.tickets.find(t => t.id === id);
                if (!ticket) {
                    this.$router.replace({ name: 'notFound' });
                }
                else {
                    this.ticket = cloneDeep(ticket);
                    this.getComments(id);

                    this.getAttachments(id);
                }
            }

        },
        filters: {
            statusText(value) {
                let statusText = '';
                switch (value) {
                    case TicketStatus.New:
                        statusText = 'Новый';
                        break;
                    case TicketStatus.InWorking:
                        statusText = 'В работе';
                        break;
                    case TicketStatus.Closed:
                        statusText = 'Закрыт';
                        break;
                    default:
                }
                return statusText;
            }
        },
        methods: {
            ...mapActions('ticketModule', [
                'GET_TICKETS',
                'GET_TICKET',
                //'CLOSED_TICKET',
                'SAVE_TICKET'
            ]),
            async getComments(ticketId, page) {
                this.loadingComments = true;
                const take = 10;
                const skip = page ? take * (page - 1) : 0;
                let { items, totalCount } = await ticketService.getComments(ticketId, skip, take);
                this.comments = items;
                this.commentsTotalCount = totalCount;

                this.commentsPage = page;
                this.loadingComments = false;
            },
            async getAttachments(ticketId) {
                this.loadingFiles = true;
                let { items, totalCount } = await ticketService.getAttachments(ticketId, 0, 100);
                this.attachments = items;
                this.loadingFiles = false;
            },
            changeCommentPage(page) {
                this.getComments(this.ticket.id, page);
            },
            showText() {
                this.show = !this.show;
            },
            toAddComment() {
                this.addCommentInProcess = true;
                //debugger
                //setTimeout(() => {
                //    this.$refs.comment.focus();
                //})

                // this.$refs.comment.$el.focus();
            },
            async closedTicket() {
                const id = this.ticket.id;
                const today = moment().format();
                const ticket = cloneDeep(this.tickets.find(t => t.id === id));

                const ticketStatus = this.ticket.status;
                const ticketClosedDate = this.ticket.closedDate;
                if (ticket) {
                    this.ticket.status = 2;
                    this.ticket.closedDate = today;
                }
                try {
                    await ticketService.saveTicket(this.ticket);
                } catch (e) {
                    console.error(e);
                    this.$notify({
                            title: 'Ошибка',
                            text: 'Не удалось закрыть тикет',
                            type: 'error'
                        });
                }
            },
            async addComment() {
                if (this.commentText) {
                    const result = await ticketService.addComment(this.ticket.id, {
                        text: this.commentText,
                    });
                    const comment = await ticketService.getComment(this.ticket.id, result);
                    this.commentsTotalCount++;
                    this.comments.unshift(comment);
                    this.commentText = null;
                    this.addCommentInProcess = false;
                }
            },
            cancelComment() {
                this.commentText = null;
                this.addCommentInProcess = false;
            },
            async downloadFile(fileId) {
                await ticketService.downloadAttachment(fileId);
            },
            async deleteFile(fileId) {
                event.preventDefault();
                event.stopPropagation();
                await ticketService.deleteAttachment(this.ticket.id, fileId);
                this.complete();
            },
            uploadProgress(file, progress, bytesSent) {
                if (file.previewElement) {
                    var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                    progressElement.style.width = progress + "%";

                    let percents = file.previewElement.querySelector('.file-progress-percents');
                    percents.textContent = Math.round(progress) + "%";
                }
            },
            dragStart(e) {
                //debugger;
            },
            toggleExtendedAttributeDisplayable() {
                this.extendedAttributeDisplayable = !this.extendedAttributeDisplayable;
            },
            addedFile(file) {
                this.dropzone = this.$refs.dropzone;

                const maxFiles = this.dropzone.options.maxFiles;
                const maxFilesize = this.dropzone.options.maxFilesize;
                let filesQueued = this.dropzone.getQueuedFiles();
                this.addFileInProcess = this.dropzone.dropzone.files.length > 0;

                //console.log(file.size);
                if (filesQueued.length + 1 > maxFiles) {
                    this.$notify({
                        title: 'Не удалось загрузить документ',
                        type: 'error',
                        text: 'Количество файлов превышает максимально допустимое : ' + maxFiles + ' файлов ',
                    });
                    this.dropzone.removeFile(file);
                }
                else if (file.size > maxFilesize * 1024 * 1024) {
                    this.$notify({
                        title: 'Не удалось загрузить документ',
                        type: 'error',
                        text: 'Размер файла превышает максимально допустимое : ' + maxFilesize + ' мб ',
                    });
                    this.dropzone.removeFile(file);
                }
                else {
                    var previewElement = file.previewElement;
                    if (previewElement) {
                        var extensionEl = previewElement.querySelector('[data-dz-extension]');
                        var extension = '';
                        if (extensionEl != null) {
                            extension = file.name.split(".");
                            extension = extension.length > 1 ? extension[extension.length-1] : '';
                            extensionEl.textContent = extension.toUpperCase();
                        }
                        if (!checkFileExtension(extension)) {
                            this.$notify({
                                title: 'Не удалось загрузить документ',
                                type: 'error',
                                text: 'Нельзя загрузить файл данного формата: ' + extension,
                            });
                            this.dropzone.removeFile(file);
                        }
                        else {
                            var nameEl = previewElement.querySelector('[data-dz-name]');
                            if (nameEl != null) nameEl.textContent = file.name.slice(0, file.name.length - (extension.length > 0 ? (extension.length + 1) : 0));

                            var btn_delete = previewElement.querySelector('.file-delete-btn');
                            if (btn_delete != null) {
                                btn_delete.addEventListener("click", (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.dropzone.removeFile(file);
                                    this.addFileInProcess = this.dropzone.dropzone.files.length > 0;
                                });
                            }
                        }
                    }
                }
            },
            sendFiles() {
                let url = this.dropzone.options.url;
                url = url.replace('{{ticketId}}', this.ticket.id);
                this.dropzone.setOption('url', url);
                this.dropzone.processQueue();
                this.addFileInProcess = this.dropzone.dropzone.files.length > 0;
            },
            onFileSuccess(file, response) {
                if (file.previewElement) {
                    var previewElement = file.previewElement;
                    if (previewElement.classList.contains('file_in-progress')) {
                        previewElement.classList.remove('file_in-progress');
                        this.dropzone.removeFile(file);
                        this.addFileInProcess = this.dropzone.dropzone.files.length > 0;
                    }
                }
            },
            onFileCanceled(file) {
            },
            onFileError(file, message, xhr) {
                if (file.previewElement) {
                    this.$notify({
                        title: 'Не удалось загрузить документ',
                        type: 'error',
                        text: message,
                    });
                    var previewElement = file.previewElement;
                    if (!previewElement.classList.contains('file_error')) {
                        previewElement.classList.add('file_error');
                    }
                    if (previewElement.classList.contains('file_in-progress')) {
                        previewElement.classList.remove('file_in-progress');
                    }
                    let errorWrapper = previewElement.querySelector('.file-error-wrapper');
                    errorWrapper.textContent = message;
                }
            },
            duplicateFile() {

            },
            sendingFile(file, xhr, formData) {
                formData.append("sendMail", true);
                if (file.previewElement) {
                    var previewElement = file.previewElement;
                    if (!previewElement.classList.contains('file_in-progress')) {
                        previewElement.classList.add('file_in-progress');                        
                    }
                }                
            },
            queueCompleteFile() {
            },
            onFileComplete(response) {
                this.complete();
            },
            complete() {
                this.getAttachments(this.ticket.id);
            },
        },
        components: {
            VueDropzone,
            vueCommentsBlock,
            VueExtendedAttributeDisplay,
            VueExtendedAttributeInput
        }
    };


</script>
<style>
    .ticket-container {
        padding-bottom: 20px;
    }

        .ticket-container .plate {
            border-bottom: none;
        }

    .comment > div {
        display: inline-block;
        vertical-align: top;
    }

    .comment img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
    }

    .comment .comment-content {
        width: calc(100% - 30px);
    }

    .ticket-page .icon-container {
        position: initial;
        margin-top: 10px;
    }

    .file-list li.file {
        list-style-type: none;
        display: inline-block;
        margin-right: 5px;
    }

        .file-list li.file span {
            background: #03A9F4;
            border-radius: 13px;
            width: auto;
            padding: 3px 10px;
            color: #fff;
            cursor: pointer;
        }

    .ticket-page .plate {
        box-shadow: none;
        padding-bottom: 0px !important;
    }

    .ticket-dropzone {
        margin-top: 15px;
    }

    .ticket-description {
        min-height: 80px;
        margin-bottom: 15px;
        margin-top: 7px;
    }

    .add-comment,
    .close-ticket {
        display: inline-block;
    }

    .btn-container a {
        margin: 24px 0;
    }

    .close-ticket {
        float: right !important;
        /*text-align: right;*/
        /*margin: 48px 0 24px;*/
    }

    @media (max-width: 336px) {
        .close-ticket {
            width: 100%;
            margin: 0px 0 10px !important;
            float: left !important;
            text-align: left;
        }
    }

    #drop {
        min-height: 50px;
        margin-top: 10px;
    }
    .send-files {
        margin-top: 15px;
    }
    span.btn-deleteFile {
        padding: 0px 6px !important;
        margin: 0px 1px !important;
    }
    .btn-deleteFile:hover {
        color: #29619f !important;
    }
</style>
