<template>
    <div class="new-ticket">
        <section class="">
            <div class="container ">
                <div class=" ">
                    <a @click="$router.go(-1)" class="btn btn-neutral color-black my-4">
                        Назад
                    </a>
                </div>
                <card shadow class="ticket-list" no-body>
                    <div class="px-3">
                        <div class="row plate p-3">
                            <div class="col-lg-12 ">
                                <div>
                                    <span class="ticket-title">Новое обращение</span>
                                </div>
                                <vue-dropzone id="drop" :options="dropOptions"
                                              :use-custom-slot="true"
                                              :duplicate-check="true"
                                              ref="dropzone"
                                              @vdropzone-upload-progress="uploadProgress"
                                              @vdropzone-file-added="addedFile"
                                              @vdropzone-drag-start="dragStart"
                                              @vdropzone-success="onFileSuccess"
                                              @vdropzone-complete="onFileComplete"
                                              @vdropzone-duplicate-file="duplicateFile"
                                              @vdropzone-canceled="onFileCanceled"
                                              @vdropzone-error="onFileError"
                                              @vdropzone-sending="sendingFile"
                                              @vdropzone-queue-complete="queueCompleteFile">
                                    <form role="form">
                                        <div class="form-group" :class="{ 'form-group--error': $v.title.$error }">
                                            <base-input alternative
                                                        class="mb-2"
                                                        placeholder="Введите название обращения"
                                                        v-model="title">
                                            </base-input>
                                            <div class="error-message small text-danger" v-if="!$v.title.required">Введите название обращения</div>
                                        </div>
                                        <div class="form-group" :class="{ 'form-group--error': $v.type.$error }">

                                            <select is="vue-select-pure" 
                                                    class="mb-2" 
                                                    placeholder="Выберите тип обращения" 
                                                    v-model="type"
                                                    :options="ticketTypesForSelect">
                                            </select>

                                            <div class="error-message small text-danger" v-if="!$v.type.required">Выберите тип обращения</div>
                                        </div>

                                        <div class="form-group" :class="{ 'form-group--error': $v.text.$error }">
                                            <base-textarea class="mb-2"
                                                           placeholder="Введите текст обращения"
                                                           v-model="text"
                                                           :rows="5">
                                            </base-textarea>
                                            <div class="error-message small text-danger" v-if="!$v.text.required">Введите описание обращения</div>
                                            <!--<textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>-->
                                        </div>
                                        <div v-if="extendedAttributesForDisplay && extendedAttributesForDisplay.length > 0"
                                             class="mb-4">
                                            <a @click="toggleExtendedAttributeDisplayable"
                                               class="d-inline-block mb-3 mt-4 font-weight-bold">
                                                <span>
                                                    <span v-show="extendedAttributeDisplayable" class="fa fa-caret-up"></span>
                                                    <span v-show="!extendedAttributeDisplayable" class="fa fa-caret-down"></span>
                                                    Дополнительные атрибуты
                                                </span>
                                            </a>
                                            <div v-show="extendedAttributeDisplayable">
                                                <div v-for="attr of extendedAttributesForDisplay"
                                                     :key="attr.name"
                                                     class="form-group">
                                                    <div v-if="attr.schema.isReadOnly">
                                                        <vue-extended-attribute-display :attribute="attr"
                                                                                        :attribute-schema="attr.schema">
                                                        </vue-extended-attribute-display>
                                                    </div>
                                                    <div v-else>
                                                        <vue-extended-attribute-input class="mb-2"
                                                                                        ref="extendedAttribute"
                                                                                      :attribute="attr"
                                                                                      :attribute-schema="attr.schema"
                                                                                      @update:valid="(isValid) => onValidAttr(attr.name, isValid)"
                                                                                      @update-value="updateExtendedAttributeValue">
                                                        </vue-extended-attribute-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div id="ticket-file-container" class="file-container">
                                            </div>
                                            <span>
                                                <span class="fa fa-paperclip text-danger"></span> Переместите файл сюда или
                                                <a id="file-btn-upload" class="file-btn-upload text-muted">загрузите с компьютера</a>
                                            </span>
                                        </div>
                                        <div class="text-left">
                                            <base-button v-if="!isFilesError"
                                                         type="danger"
                                                         class="my-4"
                                                         :class="{'disabled' : !isValid || isSaving}"
                                                         @click="onSave">
                                                Создать обращение
                                            </base-button>
                                            <base-button v-else
                                                         type="danger"
                                                         class="my-4"
                                                         @click="$router.push('/tickets')">
                                                Продолжить
                                            </base-button>
                                        </div>
                                    </form>
                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>
    //TODO:Вынести dropzone в отдельный компонент
    import { mapState, mapActions, mapGetters } from 'vuex';
    import ApiService from '../services/api.service';
    import TokenService from '../services/token.service';
    import TicketService from '../services/ticket.service';
    import { required, minLength } from 'vuelidate/lib/validators';
    import VueDropzone from 'vue2-dropzone';
    import VueExtendedAttributeDisplay from '@/components/VueExtendedAttributeDisplay';
    import VueExtendedAttributeInput from '@/components/VueExtendedAttributeInput';

    const getPreviewTemplate = () => `<div class="file-item ">
                                                <div class="file-extension text-muted" data-dz-extension ></div>
                                                <div class="file-description">
                                                    <span class="file-name" data-dz-name></span>
                                                    <span class="file-delete-btn"></span>
                                                    <span class="file-progress-percents">0%</span>
                                                    <div class="file-progress-bar-wrapper">
                                                        <div class="file-progress-bar" data-dz-uploadprogress></div>
                                                    </div>
                                                    <div class="file-error-wrapper">

                                                    </div>
                                                </div>`;

    var checkFileExtension = function (fileExtension) {
        if (fileExtension) {
            var alowedFileExtensions = [
                'TIFF', 'TIF', 'JPEG', 'JPG',
                'JPE', 'BMP', 'PNG', 'PIC',
                'GIF', 'PSD', 'RAW', 'TGA',
                'WDP', 'HDP', 'CPT', 'CDR',
                'CGM', 'WMF', 'EMF', 'SVG',
                'AI', 'MSG',
                'DOC', 'DOCX', 'DOCM', 'DOT',
                'DOTM', 'DOTX', 'XLS', 'XLSX',
                'XLR', 'XLSM', 'XLT', 'XLTM',
                'XTLX', 'PPTX', 'PPT', 'POT',
                'POTM', 'POTX', 'PPS', 'PPSM',
                'PPSX', 'PPTM', 'SLDM', 'PDF',
                'DJVU', 'DJV', 'ODF', 'RTF',
                'TXT', 'CSV', 'KEY', 'PAGES',
                'EPUB', 'FB2', 'IBOOKS', 'GPX',
                'KML', 'MDF', 'MOBI', 'PUB,',
                'MSO', 'ODS', 'ODT', 'SNB',
                'WPD', 'WPS', 'XPS',
                'AIFF', 'AIF', 'AU', 'SND',
                'MID', 'MIDI', 'MP3', 'MPGA',
                'M4A', 'MP4', 'MPG4', 'WAV',
                'WAVE', 'BWF', 'WMA', 'MP2',
                'ASF', 'AVI', 'M4V', 'MOV',
                'MPG', 'MPEG', 'SWF', 'WMV',
                'VFW', 'MPE', 'M75', 'M15',
                'M2V', 'TS', 'QT', 'DV',
                'DIF', '3GP', 'MKV', 'WEBM',
                'RAR', 'ZIP', '7Z'
            ];
            for (var i = 0; i < alowedFileExtensions.length; i++)
                if (fileExtension.toUpperCase().indexOf(alowedFileExtensions[i]) > -1)
                    return true;
        }
    };
    export default {
        components: {
            VueDropzone,

            VueExtendedAttributeInput,
            VueExtendedAttributeDisplay
        },
        data() {
            return {
                //show: false,
                type: '',
                title: '',
                text: '',
                extendedAttributes: [],

                extendedAttributeDisplayable: true,

                isSaving: false,
                isCompleteSave: false,
                isFilesError:false,
                //dropzone: null,
                options: [
                  //{ value: null, text: 'Выберите тип обращения' },
                  { value: 'Заявка на обслуживание', text: 'Заявка на обслуживание' },
                  { value: 'Обратная связь', text: 'Обратная связь' },
                  //{ value: { C: '3PO' }, text: 'This is an option with object value' },
                  //{ value: 'd', text: 'This one is disabled', disabled: true }
                ],
                //files: ['sdf'],
                dropOptions: {
                    url: `${ApiService.getBaseUrl()}/tickets/{{ticketId}}/files`,
                    headers: {
                        'Authorization': ApiService.getHeader('Authorization')//TokenService.getToken()
                    },
                    parallelUploads: 10,
                    maxFiles:10,
                    maxFilesize: 50,
                    autoProcessQueue: false,
                    dictDefaultMessage: 'Перенесите файлы сюда или загрузите с компьютера',
                    dictResponseError: 'Не удалось загрузить файл',
                    dictFallbackMessage: "Ваш браузер не поддерживает перетаскивание файлов",
                    dictFallbackText: "Пожалуйста, воспользуйтесь формой загрузки",
                    dictFileTooBig: "Размер файла ({{filesize}}MiB) превышает максимально допустимый: {{maxFilesize}}MiB",
                    dictInvalidFileType: "Вы не можете загрузить файлы данного формата",
                    //dictMaxFilesExceeded: "Превышен лимит в {{maxFiles}} файлов",
                    previewTemplate: getPreviewTemplate(),
                    previewsContainer: '.file-container',
                    clickable: '.file-btn-upload',
                },
                invalidedAttributeNames: []
            }
        },
        computed: {
            ...mapState('ticketModule', [
                'newTicketId',
                'ticketTypes'
            ]),
            ...mapGetters('organizationModule', {
                'ticketAttributeSchema': 'ticketAttributeSchema',
            }),
            schemaAttributes(){
                return this.ticketAttributeSchema && this.ticketAttributeSchema.attributes;
            },
            extendedAttributesForDisplay(){
                let result = [];
                if(this.schemaAttributes) {
                    result = this.schemaAttributes.map(attr => {
                            const ticketAttribute = this.extendedAttributes
                                                        && this.extendedAttributes.find(x => x.name == attr.name);
                            return ({
                                name: attr.name,
                                caption: attr.caption,
                                valueType: attr.type,
                                value: (ticketAttribute && ticketAttribute.value) || null,
                                description: (ticketAttribute && ticketAttribute.description) || null,
                                schema: attr
                            })
                    });
                }
                return result;
            },
            isValid(){
                return !this.$v.$invalid && this.invalidedAttributeNames.length === 0;
            },
            ticketTypesForSelect() {
                return this.ticketTypes.map(str => ({
                    id: str,
                    text: str
                }));
            }
        },
        validations(){
            const validationModel = {
                title: {
                    required,
                    //minLength: minLength(4)
                },
                type: {
                    required,
                    //minLength: minLength(4)
                },
                text: {
                    required,
                    //minLength: minLength(4)
                },
                // extendedAttributes:{
                //     $each: {
                //         value: {
                //             dynamicSerializedValue
                //         }
                //     }
                // }
            }
            return validationModel;
        },
        mounted() {
            this.dropzone = this.$refs.dropzone;
        },
        methods: {
            ...mapActions('ticketModule', [
                'GET_TICKETS',
                'SAVE_TICKET',
                'ADD_TICKET',
                'DELETE_TICKET'
            ]),
            async onSave() {
                this.$v.$touch();
                if(this.$refs.extendedAttribute && this.schemaAttributes){
                    this.$refs.extendedAttribute.forEach((vm) => {
                        vm.validate();
                    });
                }
                if (this.isValid && !this.isSaving) {
                    this.isSaving = true;

                    let extendedAttributesForSave = [];
                    if (this.schemaAttributes) {
                        extendedAttributesForSave = this.schemaAttributes.map(attrSchema => {
                            const attr = this.extendedAttributes.find(extAttr => extAttr.name === attrSchema.name);
                            if (attr) {
                                return attr;
                            }
                            else {
                                return {
                                    name: attrSchema.name,
                                    value: null,
                                    description: null,
                                    valueType: attrSchema.type
                                }
                            }
                        });
                    }
                    try {
                        await this.ADD_TICKET({
                            type: this.type,
                            title: this.title,
                            text: this.text,
                            extendedAttributes: extendedAttributesForSave
                        });
                        let url = this.dropzone.options.url;
                        url = url.replace('{{ticketId}}', this.newTicketId);//this.newTicketId

                        this.complete();
                        this.sendFiles(url);

                        this.$notify({
                            title: 'Тикет успешно добавлен',
                            type: 'success'
                        });
                    }
                    catch(error){
                        console.log(error);
                        this.$notify({
                            title: 'Ошибка',
                            text: 'Тикет не был добавлен',
                            type: 'error'
                        });
                    }
                    this.isSaving = false;
                }
            },
            uploadProgress(file, progress, bytesSent) {
                if (file.previewElement) {
                    var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                    progressElement.style.width = progress + "%";

                    let percents = file.previewElement.querySelector('.file-progress-percents');
                    percents.textContent = 	Math.round(progress) + "%";
                }
            },
            dragStart(e) {
                //debugger;
            },
            addedFile(file) {
                const maxFiles = this.dropzone.options.maxFiles;
                const maxFilesize = this.dropzone.options.maxFilesize;
                let filesQueued = this.dropzone.getQueuedFiles();



                //console.log(file.size);
                if (filesQueued.length + 1 > maxFiles) {
                    this.$notify({
                        title: 'Не удалось загрузить документ',
                        type: 'error',
                        text: 'Количество файлов превышает максимально допустимое : ' + maxFiles + ' файлов ',
                    });
                    this.dropzone.removeFile(file);
                }
                else if (file.size > maxFilesize * 1024 * 1024) {
                    this.$notify({
                            title: 'Не удалось загрузить документ',
                            type: 'error',
                            text: 'Размер файла превышает максимально допустимое : ' + maxFilesize + ' мб ',
                        });
                    this.dropzone.removeFile(file);
                }
                else {
                    var previewElement = file.previewElement;
                    if (previewElement) {
                        var extensionEl = previewElement.querySelector('[data-dz-extension]');
                        var extension = '';
                        if (extensionEl != null) {
                            var regexp = /.([\w]+)$/;
                            extension = file.name.match(regexp);
                            extension = extension.length > 1 ? extension[1] : '';
                            extensionEl.textContent = extension.toUpperCase();
                        }
                        if (!checkFileExtension(extension)) {
                            this.$notify({
                                title: 'Не удалось загрузить документ',
                                type: 'error',
                                text: 'Нельзя загрузить файл данного формата: ' + extension,
                            });
                            this.dropzone.removeFile(file);
                        }
                        else {
                            var nameEl = previewElement.querySelector('[data-dz-name]');
                            if (nameEl != null) nameEl.textContent = file.name.slice(0, file.name.length - (extension.length > 0 ? (extension.length + 1) : 0));

                            var btn_delete = previewElement.querySelector('.file-delete-btn');
                            if (btn_delete != null) {
                                btn_delete.addEventListener("click", (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.dropzone.removeFile(file);
                                });
                            }
                        }
                    }
                }
            },
            sendFiles(url) {
                if (url) {
                    this.dropzone.setOption('url', url);
                }
                this.dropzone.processQueue();
            },
            removeFile() {

            },
            onFileSuccess(file, response) {
                if (file.previewElement) {
                    var previewElement = file.previewElement;
                    if (previewElement.classList.contains('file_in-progress')) {
                        previewElement.classList.remove('file_in-progress');
                    }
                }
            },
            onFileCanceled(file) {
            },
            onFileError(file, message, xhr) {
                if (file.previewElement) {
                    this.$notify({
                        title: 'Не удалось загрузить документ',
                        type: 'error',
                        text: message,
                    });
                    var previewElement = file.previewElement;
                    if (!previewElement.classList.contains('file_error')) {
                        previewElement.classList.add('file_error');
                    }
                    if (previewElement.classList.contains('file_in-progress')) {
                        previewElement.classList.remove('file_in-progress');
                    }
                    let errorWrapper = previewElement.querySelector('.file-error-wrapper');
                    errorWrapper.textContent = 	message;
                }
            },
            duplicateFile() {

            },
            sendingFile(file, xhr, formData) {
                if (file.previewElement) {
                    var previewElement = file.previewElement;
                    if (!previewElement.classList.contains('file_in-progress')) {
                        previewElement.classList.add('file_in-progress');
                    }
                }
            },
            queueCompleteFile() {
            },
            onFileComplete(response) {
                this.complete();
            },
            complete() {
                let uploadingFiles = this.dropzone.getUploadingFiles();
                let filesAccepted = this.dropzone.getAcceptedFiles();
                if (filesAccepted.every(f => f.status === 'success' || f.status === 'error')) {
                    this.$router.push('/tickets');
                }
            },
            updateExtendedAttributeValue(name, value, description){
                const attrIndex = this.extendedAttributes.findIndex(attr => attr.name === name);
                const attrSchema = this.schemaAttributes.find(attr => attr.name === name);
                if(attrIndex !== -1){
                    this.extendedAttributes.splice(attrIndex, 1, {
                        ...this.extendedAttributes[attrIndex],
                        value: value,
                        description: description
                    });
                }
                else{
                    this.extendedAttributes.push({
                        name: attrSchema.name,
                        valueType: attrSchema.type,
                        value: value,
                        description: description
                    })
                }
                //attr. =;
            },
            toggleExtendedAttributeDisplayable(){
                this.extendedAttributeDisplayable = !this.extendedAttributeDisplayable;
            },
            onValidAttr(attrName, isValid){
                const index = this.invalidedAttributeNames.indexOf(attrName);
                if(!isValid && index === -1){
                    this.invalidedAttributeNames.push(attrName);
                }
                else if(isValid && index !== -1){
                    this.invalidedAttributeNames.splice(index, 1);
                }
            }
        },
        
    };


</script>
<style lang="scss">
    .new-ticket .input-group-alternative {
        width: 100%;
    }

    /*TODO: не забыть убрать*/
    .form-group.input-group-alternative.focused {
        border-bottom: 1px solid black;
    }

    textarea.form-control {
        transition: none;
        border: none;
        border-bottom: 1px solid #cad1d7;
        border-radius: 0px;
        padding: 0px 0;
    }

    .form-group.focused textarea.form-control {
        border-bottom: 1px solid black; /*.input-group-alternative*/
    }

    .file-btn-upload {
        cursor: pointer;
    }

    .file-progress-bar-wrapper {
        width: 100%;
        height: 5px;
        background: #dadada;
        /*display:none;*/
        opacity: 0;
    }

    .file-progress-bar {
        width: 00%;
        height: 100%;
        background: rgb(255, 189, 189);
        transition: width linear 0.2s;
    }

    .file-extension {
        width: 50px;
        font-size: 12px;
        padding-top: 3px;
    }

    .file-description {
        width: 100%;
    }

    .file-delete-btn,
    .file-progress-percents {
        float: right;
    }

    .file-progress-percents {
        display: none;
    }

    .file-delete-btn {
        margin-left: 10px;
        cursor: pointer;
    }

    .file-container {
        margin-bottom: 5px;
    }

    .file-item {
        display: flex;
        margin-left: -5px;
        padding: 5px;
    }

    .file-item:hover {
        background: rgba(34, 34, 34, 0.05);
    }

    .file-item.file_in-progress .file-progress-bar-wrapper {
        opacity: 1;
    }

    .file-item.file_in-progress .file-progress-percents {
        display: block;
    }

    .file-item .file-error-wrapper {
        display: none;
    }

    .file-item.file_error .file-error-wrapper {
        color: red;
        display: block;
    }

    .file-item.dz-complete .file-delete-btn {
        display: none;
    }

    .form-group:not(.form-group--error) .error-message {
        display: none;
    }

    .file-item .file-delete-btn:before {
        content: url(../assets/img/delete-row.png);
    }
</style>
