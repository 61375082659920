import Badge from "../components/Badge";
import BaseAlert from "../components/BaseAlert";
import BaseButton from "../components/BaseButton";
import BaseCheckbox from "../components/BaseCheckbox";
import BaseInput from "../components/BaseInput";
import BaseTextarea from "../components/BaseTextarea";
import BasePagination from "../components/BasePagination";
import BaseProgress from "../components/BaseProgress";
import BaseRadio from "@/components/BaseRadio";
import BaseSlider from "@/components/BaseSlider";
import BaseSwitch from "@/components/BaseSwitch";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import VueLoader from '@/components/Loader.vue';
import VueSelect from '@/components/VueSelect.vue';

import moment from 'moment';
import  'moment/locale/ru.js';
moment.locale('ru');

//import 'pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min.js';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

import VueDatetimepickerRange from '@/components/VueDatetimepickerRange.vue';
import VueDateTimePicker from '@/components/VueDatetimepicker.vue';

export default {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSlider.name, BaseSlider);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Icon.name, Icon);
        Vue.component(BaseTextarea.name, BaseTextarea);
        Vue.component(VueLoader.name, VueLoader);
        Vue.component('vue-datetimepicker-range', VueDatetimepickerRange);
        Vue.component('vue-datetimepicker', VueDateTimePicker);
        Vue.component('vue-select-pure', VueSelect);
  }
};
