<template>
    <div>
        <label class="">{{attributeSchema.caption}}</label>
        <div v-if="attributeSchema.type == ValueType.STRING">
            <base-input alternative
                        v-model="attributeValue"
                        :placeholder="`Введите ${attributeSchema.caption}`">

            </base-input>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.TEXT">
            <base-textarea alternative
                           v-model="attributeValue"
                           :placeholder="`Введите ${attributeSchema.caption}`">

            </base-textarea>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.INT">
            <base-input alternative
                        v-model="attributeValue"
                        :placeholder="`Введите ${attributeSchema.caption}`">

            </base-input>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DOUBLE">
            <base-input alternative
                        v-model="attributeValue"
                        :placeholder="`Введите ${attributeSchema.caption}`">

            </base-input>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.BOOL">
            <base-checkbox alternative
                           v-model="attributeValue">

            </base-checkbox>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DICTIONARY">
            <vue-select-pure ref="select"
                             v-model="attributeValue"
                             :placeholder="`Введите ${attributeSchema.caption}`"
                             :minimum-results-for-search="0"
                             :ajax-headers="dictionarySourceHeaders"
                             allow-clear
                             :url="dictionaryElementsSourceLink">
                <option v-if="attribute.value"
                        value="attribute.value">
                    {{attribute.description}}
                </option>
            </vue-select-pure>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DATE">
            <vue-datetimepicker v-model="attributeValue"
                                is-all-day>

            </vue-datetimepicker>
        </div>
        <div v-else-if="attributeSchema.type == ValueType.DATETIME">
            <vue-datetimepicker v-model="attributeValue">

            </vue-datetimepicker>
        </div>
        <div class="small text-danger">
            <div v-show="$v.attributeValue.$error && $v.attributeValue.required === false">
                Поле является обязательным для заполнения
            </div>
            <div v-show="$v.attributeValue.$error && $v.attributeValue.isNumber === false">
                Значение должно быть целым числом
            </div>
            <div v-show="$v.attributeValue.$error && $v.attributeValue.isDouble === false">
                Значение должно быть числом с плавающей точкой
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery';
    import { ValueType } from "@/models/base";
    import ApiService from '@/services/api.service.js';
    import { requiredIf, helpers } from 'vuelidate/lib/validators';

    const isNumber = helpers.regex('isNumber', /^\d+$/);
    const isDouble = helpers.regex('isDouble', /^\d+([.|,]\d+)?$/);

    export default {
        name: "vue-extended-attribute-input",
        props: {
            attribute: {
                type: Object,
                required: true
            },
            attributeSchema: {
                type: Object,
                required: true
            },
            valid: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                attributeValue: this.getAttributeValue(this.attribute)
            };
        },
        computed: {
            ValueType() {
                return ValueType;
            },
            dictionaryElementsSourceLink() {
                let result = '';
                switch (this.attributeSchema.sourceType) {
                    case 1: {//internal
                        const url = new URL(ApiService.getBaseUrl());
                        result = url.origin + this.attributeSchema.source;
                        break;
                    }
                    case 0: {//external
                        result =  this.attributeSchema.source;
                        break;
                    }
                }
                return result;
            },
            dictionarySourceHeaders() {
                const headers = ApiService.getHeaders();
                return {
                    'Authorization': headers.Authorization,
                    'OrganizationId': headers.OrganizationId
                }
            }
        },
        watch: {
            async attributeValue(newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (!this.$v.$invalid) {
                        let description = null;
                        await this.$nextTick();
                        if (this.attributeSchema.type === ValueType.DICTIONARY) {
                            const $select = $(this.$refs.select.$el);
                            const data = $select.select2('data');
                            if (data.length > 0)
                                description = data.map(item => item.text).join();
                        }
                        this.$emit('update-value', this.attributeSchema.name, this.setAttributeValue(newVal), description);
                    }
                }
            },
            '$v.$invalid': {
                handler(value) {
                    this.$emit('update:valid', !value);
                },
                immediate: true

            }
        },
        validations() {
            const attributeValue = {
                required: requiredIf(() => {
                    return this.attributeSchema.required;
                })
            }
            switch (this.attributeSchema.type) {
                case ValueType.INT:
                    attributeValue.isNumber = isNumber;
                    break;
                case ValueType.DOUBLE:
                    attributeValue.isDouble = isDouble;
                    break;
            }
            return {
                attributeValue
            }
        },
        methods: {
            getAttributeValue(attribute) {
                let result = this.attributeSchema.valueByDefault || '';
                //if(attribute.value){
                switch (this.attributeSchema.type) {
                    case ValueType.STRING:
                    case ValueType.TEXT:
                        result = attribute.value;
                        break;
                    case ValueType.INT:
                    case ValueType.DOUBLE:
                        result = Number(attribute.value);
                        break;
                    case ValueType.DICTIONARY:
                        result = attribute.value ? attribute.value.split(',') : null;
                        break;
                    case ValueType.DATE:
                    case ValueType.DATETIME:
                        result = attribute.value;
                        break;
                    case ValueType.BOOL:
                        result = attribute.value === 'True';
                        break;
                }
                //}
                return result;
            },
            setAttributeValue(value) {
                let result = null;
                if (value) {
                    switch (this.attributeSchema.type) {
                        case ValueType.STRING:
                        case ValueType.TEXT:
                            result = value;
                            break;
                        case ValueType.INT:
                            result = value;
                            break;
                        case ValueType.DOUBLE:
                            result = value;//.toFixed(2)
                            break;
                        case ValueType.DICTIONARY:
                            if (Array.isArray(value))
                                result = value.join();
                            else
                                result = `${value}`;
                            break;
                        case ValueType.DATE:
                        case ValueType.DATETIME:
                            result = value;
                            break;
                        case ValueType.BOOL:
                            result = value === true ? 'True' : 'False';
                            break;
                    }
                }
                return result;
            },
            validate() {
                this.$v.$touch();
            }
            // setDictionaryElements(response){
            //   debugger
            // },
            // dictionaryRequest(params){
            //   const headers = ApiService.getHeaders();
            //   var $request = $.ajax(
            //   {
            //       ...params,
            //       headers: {
            //         'Authorization': headers.Authorization,
            //         'OrganizationId': headers.OrganizationId,
            //         //content
            //       }
            //   });

            //   $request.then(this.setDictionaryElements);
            //   //$request.fail(failure);

            //   return $request;

            // }
        }
    };
</script>
<style>
</style>
